import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import MoreIcon from "@mui/icons-material/ExpandMoreOutlined";
import { useRecordContext, useRedirect } from "react-admin";
import { Button, Menu, MenuItem } from "@material-ui/core";
import RefreshStatusButton from "./RefreshStatusButton";

const useStyles = makeStyles((theme) => ({
    icon: {
      marginLeft: theme.spacing(0.5),
      width: 20,
    },
    menuItem: {
      padding: theme.spacing(1),
    },
    actionButton: {
      justifyContent: "flex-start",
      padding: theme.spacing(1, 2),
      width: "100%",
      textAlign: "left",
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
    },
}));

const MacActionMenu = () => {

    const record = useRecordContext();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const redirect = useRedirect();

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const handleEdit = () => {
        const editUrl = `/machines/edit/${record.id}`;
        redirect(editUrl);
    };

    return (
        <>
            <Button
                aria-haspopup="true"
                onClick={handleClick}
                size="small"
                endIcon={<MoreIcon className={classes.icon} />}
            >
                More
            </Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleEdit} className={classes.menuItem}>
                    {record && (
                        <Button
                            label="Edit"
                            className={classes.actionButton}
                            color="primary"
                        >Edit</Button>
                    )}
                </MenuItem>
                <RefreshStatusButton />
            </Menu>
        </>
    );
}

export default MacActionMenu;
