import React from "react";
import { Table } from "react-bootstrap";

import "../components/css/LatestTrans.css";

const MachinePerTable = () => {
    const data  = [
        {
            "machineUUID": "VE00001",
            "frequency": "2135",
            "totalSales": "RM 10580.20"
        },
        {
            "machineUUID": "VE00003",
            "frequency": "2399",
            "totalSales": "RM 8716.40"
        },
        {
            "machineUUID": "VE00004",
            "frequency": "1821",
            "totalSales": "RM 6147.30"
        },
        {
            "machineUUID": "VE00002",
            "frequency": "1771",
            "totalSales": "RM 4925.50"
        },
    ];

    return(
        <>
            <div className="macTabDiv">
                <h6 className="lblMachineTable">Machine Ranking</h6>

                <Table striped bordered hover responsive size="sm">
                    <thead>
                        <tr>
                            <th>Machine UUID</th>
                            <th>Frequency</th>
                            <th>Sales (RM)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((items, index) => (
                            <tr key={index}>
                                <td className="fontHandle" key={items.machineUUID}>{items.machineUUID}</td>
                                <td className="fontHandle" key={items.frequency}>{items.frequency}</td>
                                <td className="fontHandle" key={items.totalSales}>{items.totalSales.replace("RM", "").trim()}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </>
    );
};

export default MachinePerTable;