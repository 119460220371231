import React, { useEffect, useState } from 'react'
import { auth } from '../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useDataProvider, TopToolbar, CreateButton, ExportButton } from 'react-admin';
import EmployeeTypeList from '../../components/EmployeeType/EmployeeTypeList';
import _extendLayout from '../../layout/_extendLayout';

const EmployeeTypes = (props) => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [employeeType, setEmployeeType] = useState(null);
    const [clientId, setClientId] = useState("");
    const dataProvider = useDataProvider();

    useEffect(() => {
        const checkAuth = onAuthStateChanged(auth, (user) => {
            if(user) {
                fetchCurrentUser(user.uid);
            }
        });
        
        return () => checkAuth();
    }, []);

    const fetchCurrentUser = async (userId) => {
        try {
            const res = await dataProvider.getOne("users", { id: userId });
            fetchEmployeTypeList(res.data.client.id);
            setClientId(res.data.client.id)
            if(res.data.role === "admin") {
                setIsAdmin(true);
            } else {
                setIsAdmin(false);
            }
        } catch (error) {
            console.log("error: ", error);
        }
    };

    const fetchEmployeTypeList = async (clientId) => {
        try {
            const { data: employeeTypes } = await dataProvider.getList("employeeTypes", {
                filter: { "client": clientId },
                pagination: { page: 1, perPage: 1000 },
            });
            setEmployeeType(employeeTypes);
        } catch (error) {
            console.log("error: ", error);
        }
    };

    const typeExporter = (datas) => {
        try {
            const headers = [
                "Id", "Client", "CreatedAt", "Credit", "Name", "UpdatedAt"
            ];

            const typesForExport = datas.map(d => ({
                id: d.id || "",
                client: d.client || "",
                createdAt: d.createdAt?.toDate ? d.createdAt.toDate().toLocaleString() : d.createdAt?.seconds ? new Date(d.createdAt?.seconds * 1000).toLocaleString() : "",
                credit: d.credit || "",
                name: d.name || "",
                updatedAt: d.updatedAt?.toDate ? d.updatedAt.toDate().toLocaleString() : d.updatedAt?.seconds ? new Date(d.updatedAt?.seconds * 1000).toLocaleString() : "",
            }));

            const csvContent = [
                headers.join(","),
                ...typesForExport.map(t => [
                    `"${t.id}"`, `"${t.client}"`, `"${t.createdAt}"`, `"${t.credit}"`, `"${t.name}"`, `"${t.updatedAt}"`
                ].join(","))
            ].join("\n");

            const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "employeeTypes.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch(error) {
            console.log("error: " ,error)
        }
    };

    const CustomActions = ({ searchInput, ...props }) => (
        <TopToolbar style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
            {searchInput}
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <CreateButton label="Create" />
                <ExportButton label="Export" {...props} exporter={typeExporter} />
            </div>
        </TopToolbar>
    );

    return (
        <>
            {employeeType ? (
                <_extendLayout>
                    <EmployeeTypeList {...props} employeeType={employeeType} isAdmin={isAdmin} clientId={clientId} actions={<CustomActions />} />
                </_extendLayout>
            ) : (
                <_extendLayout>
                    <p>Loading...</p>
                </_extendLayout>
            )}            
        </>
    )
};

export default EmployeeTypes
