import React, { useState, useRef, useEffect } from 'react'
import NavBar from '../components/NavBar'
import SideBar from '../components/SideBar'
import Notification from "../components/Notification";
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const _extendLayout = ({ children, isModalOpen }) => {
    const [collapsed, setCollapsed] = useState(true);
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [toggled, setToggled] = useState(false);
    const [broken, setBroken] = useState(false);
    const notificationRef = useRef(null);
    const [results, setResult] = useState(null);
    const [unReadCount, setUnReadCount] = useState(0);
    const [userAccess, setUserAccess] = useState([]);
    const db = getFirestore();

    const sidebarStyle = isModalOpen
        ? { opacity: 0.5, pointerEvents: 'none', transition: 'opacity 0.3s ease' }
        : {};

    const toggledMoibleSidebar = () => {
        setToggled(!toggled);
    };

    const handleMouseEnter = () => {
        setCollapsed(false);
    };

    const handleMouseLeave = () => {
        setCollapsed(true);
    };

    const toggleNotificationList = () => {
        setIsNotificationOpen(!isNotificationOpen)
    };

    const handleClickOutside = (event) => {
        if(notificationRef.current && !notificationRef.current.contains(event.target)) {
            setIsNotificationOpen(false);
        }
    };

    const fetchNotifications = async () => {
        try {
            const token = localStorage.getItem('token'); // Or however you store the token
            const response = await axios.get(`http://localhost:5001/api/notification`, {
                params: {
                    skip: 0,
                    limit: 10,
                    authToken: token
                }
            });
            if(response.data) {
                setResult(response.data)
                setUnReadCount(response.data.result.unRead_count)
            } else {
                console.log("No data found");
            }
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchUserAccess = async () => {
        const auth = getAuth();
        const currentUser = auth.currentUser;
        if(currentUser) {
            try {
                const userDocRef = doc(db, "users", currentUser.uid);
                const userSnapshot = await getDoc(userDocRef);

                if (userSnapshot.exists()) {
                    const userData = userSnapshot.data();
                    const access = userData.access || [];
                    setUserAccess(access); // Default to an empty array if no access field
                    localStorage.setItem("userAccess", JSON.stringify(access));
                } else {
                    console.log("No access field found for the user.");
                }
            } catch (err) {
                console.log('Error fetching user access:', err)
            }
        }
    };

    const refreshTokenBeforeExpiry = async () => {
        const auth = getAuth();
        const currentUser = auth.currentUser;
        if(currentUser) {
            try {
                const idTokenResult = await currentUser.getIdTokenResult();
                const expirationTime = new Date(idTokenResult.expirationTime).getTime();
                const refreshTime = expirationTime - Date.now() - 5 * 60 * 1000;
                if(refreshTime > 0) {
                    setTimeout(async () => {
                        const newIdToken = await currentUser.getIdToken(true);
                        localStorage.setItem("token", newIdToken);
                        refreshTokenBeforeExpiry();
                    }, refreshTime);
                } else {
                    const newIdToken = await currentUser.getIdToken(true);
                    localStorage.setItem("token", newIdToken);
        
                    refreshTokenBeforeExpiry();
                }
            } catch (error) {
                console.log("error refresh token : ", error)
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        fetchNotifications();
        refreshTokenBeforeExpiry();
        const storedAccess = localStorage.getItem("userAccess");
        if (storedAccess) {
            setUserAccess(JSON.parse(storedAccess));
        } else {
            fetchUserAccess();
        }
    }, []);
    
    return (
        <>
            <div className="dashboard">
                <div>
                    <div style={{ display:"flex", height:"100vh" }}>
                        <div 
                            className="sidebar-container" 
                            style={{ 
                                flexShrink: 0, 
                                ...(isModalOpen
                                    ? { opacity: 0.5, pointerEvents: 'none', transition: 'opacity 0.3s ease' }
                                    : {})
                            }}>
                            <SideBar 
                                setBroken={setBroken} 
                                toggled={toggled} 
                                setToggled={setToggled} 
                                collapsed={collapsed} 
                                handleMouseEnter={handleMouseEnter} 
                                handleMouseLeave={handleMouseLeave}
                                userAccess={userAccess}
                            />
                        </div>
                        <div style={{ flex: "1", display: "flex", flexDirection:"column", height: "100vh" }}>
                            <NavBar 
                                broken={broken} 
                                toggledMoibleSidebar={toggledMoibleSidebar} 
                                toggleNotificationList={toggleNotificationList} 
                                unReadCount={unReadCount} 
                                style={{ flexShrink: 0 }}
                            />
                            {isNotificationOpen && (
                                <Notification 
                                    ref={notificationRef} 
                                    data={results} 
                                    setUnReadCount={setUnReadCount} 
                                />
                            )}
                            <div style={{ flexGrow: 1, overflowY: "auto" }}>
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default _extendLayout
