import React, { useEffect, useState } from 'react'
import { AutocompleteInput, NumberInput, ReferenceInput, required, SimpleForm, useDataProvider } from 'react-admin';
import { useRemoveSlot, useSaveSlot } from './RefreshMachineStatus';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, CardActions, CardContent, CardHeader, Grid, Box, FormControlLabel, Switch } from '@material-ui/core';
import { deleteDoc, doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import ProductForm from './ProductForm';
import _extendLayout from "../../layout/_extendLayout";

const getSlotNum = (machineSlot) => {
    const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const GRID_COL_NUM = 12;
    const row = parseInt(machineSlot / GRID_COL_NUM);
    const column = machineSlot % GRID_COL_NUM;
    if (column === 0) return `${ALPHABET[row - 1]}${column + 12}`;
    return `${ALPHABET[row]}${column}`;
};

const quantityExceedCapacity = msg => (value, values) => {
    const { maxCapacity } = values;
    if(value > maxCapacity) {
        return msg || "Invalid";
    }
};

const SlotEdit = () => {
    const { machineId, slotNumber } = useParams();
    const { saving, save } = useSaveSlot();
    const { removing, remove } = useRemoveSlot(machineId, slotNumber);
    const slotLabel = getSlotNum(slotNumber);
    const [slotDetails, setSlotDetails] = useState({});
    const [machineRecord, setMachineRecord] = useState(null);
    const [dirtyFields, setDirtyFields] = useState({});
    const [modified, setModified] = useState(false);
    const [mroAccess, setMROAccess] = useState([]);
    const [productRecord, setProductRecord] = useState(null);
    const [selectedProductId, setSelectedProductId] = useState(slotDetails?.product?.id || null);
    const [dropSensor, setDropSensor] = useState(slotDetails?.dropSensor ?? false);
    const [error, setError] = useState(slotDetails?.error ?? false);
    const navigate = useNavigate();
    const dataProvider = useDataProvider();

    useEffect(() => {
        if(!machineId) return;
        getSlotRecord();
        getMachineData();
    }, [machineId]);

    const getMachineData = async () => {
        try {
            const res = await dataProvider.getOne("machines", { id: machineId });
            setMachineRecord(res.data);
        } catch (err) {
            console.log("error: ", err);
        }
    };

    const getSlotRecord = async () => {
        try {
            const slotDocRef = doc(db, `machines/${machineId}/slots/${slotNumber}`);
            const slotDoc = await getDoc(slotDocRef);

            if(slotDoc.exists()) {
                setSlotDetails(slotDoc.data());
                setSelectedProductId(slotDoc.data()?.product?.id || null);

                setDropSensor(slotDoc.data().dropSensor);
                setError(slotDoc.data().error);
            } else {
                setSlotDetails({});
                setSelectedProductId(null);
            }
        } catch (err) {
            console.log("Error fetching machine record");
        }
    };
    
    const onSubmit = async (data) => {
        
        if(data.minCapacity <= 0) {
            alert("Min Capacity must be greater than or equal to 1.");
            return;
        };

        let obj = {
            dropSensor: dropSensor,
            error: error,
            itemNumber: data.itemNumber,
            maxCapacity: data.maxCapacity,
            minCapacity: data.minCapacity,
            priceCredit: data.priceCredit,
            product: {
                id: data.product.id,
                name: productRecord.name,
                category: productRecord.category,
                description: productRecord.description ?? "",
                image: {
                    src: productRecord.image.src ?? "",
                },
                mroAccessGroup: mroAccess,
                priceCredit: data.priceCredit ?? 0,
                priceCash: data.priceCash ?? 0,
            },
            totalQuantity: data.totalQuantity,
        }
        
        try {
            const slotDocRef = doc(db, `machines/${machineId}/slots/${slotNumber}`);

            await setDoc(slotDocRef, obj, { merge: true });

            alert(`Slot ${slotNumber} for Machine ${machineId} has been saved successfully.`);
            navigate(`/machines/edit/${machineId}`);
        } catch (err) {
            console.error("Error saving slot data:", err);
        }
    };
    
    const onRemove = async () => {
        try {
            const slotDocRef = doc(db, `machines/${machineId}/slots`, slotNumber);
            await deleteDoc(slotDocRef);

            alert(`Slot ${slotNumber} removed successfully.`);
            navigate(`/machines/edit/${machineId}`);
        } catch (error) {
            console.error("Error deleting slot:", error);
            alert("Failed to remove the slot. Please try again.");
        }
    };

    const handleOnChange = (formValues) => {
        const newDirtyFields = {};
        Object.keys(formValues).forEach(key => {
            if(formValues[key] !== slotDetails[key]) {
                newDirtyFields[key] = true;
            }
        });
        setDirtyFields(newDirtyFields);
        setModified(Object.keys(newDirtyFields).length > 0);
    };

    const handleMROAccessChange = (updatedValues) => {
        setMROAccess(updatedValues);
    };

    const handleProductRecordChange = (newProductRecord) => {
        setProductRecord(newProductRecord); // Update state in SlotEdit
    };

    const handleProductChange = (value) => {
        setSelectedProductId(value);
    };
    
    return (
        <>
            <_extendLayout>
                <CardHeader title={`Edit Slot ${slotLabel}`} />
                <CardHeader title="Slot Details" />
                <SimpleForm 
                    toolbar={null} 
                    initialvalues={{
                        ...slotDetails,
                    }} 
                    onChange={handleOnChange} 
                    onSubmit={onSubmit}
                >
                    <CardContent style={{ width: "100%" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <NumberInput
                                    label="Item number (display to user)"
                                    source="itemNumber"
                                    fullWidth
                                    validate={required("Please enter an item number")}
                                    defaultValue={slotDetails?.itemNumber}
                                />
                                <input type="hidden" name="machineSlot" value={parseInt(slotNumber)} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <NumberInput
                                    source="maxCapacity"
                                    fullWidth
                                    validate={required("Please enter a max capacity number.")}
                                    defaultValue={slotDetails?.maxCapacity}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <NumberInput
                                    label="Current Quantity"
                                    source="totalQuantity"
                                    fullWidth
                                    validate={[
                                        required("Please enter a quantity number."),
                                        quantityExceedCapacity("Quantity cannot exceed the max capacity.")
                                    ]}
                                    defaultValue={slotDetails?.totalQuantity}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <NumberInput
                                    label="Min Capacity"
                                    source="minCapacity"
                                    fullWidth
                                    validate={[
                                        quantityExceedCapacity("Quantity cannot exceed the max capacity.")
                                    ]}
                                    defaultValue={slotDetails?.minCapacity}
                                />
                            </Grid>
                            {slotDetails && (
                                <Grid item xs={12} md={6}>
                                    <FormControlLabel
                                        label="Drop Sensor"
                                        control={
                                            <Switch
                                                checked={dropSensor}
                                                onChange={(e) => setDropSensor(e.target.checked)}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label="Slot Lock"
                                        control={
                                            <Switch
                                                checked={error}
                                                onChange={(e) => setError(e.target.checked)}
                                            />
                                        }
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>

                    <CardHeader title="Select Product" />
                    <CardContent style={{ width: "100%" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} style={{ width: "100%" }}>
                                <ReferenceInput
                                    label="Product"
                                    source="product.id"
                                    reference="products"
                                    filterToQuery={() => {}}
                                    filter={{ client: machineRecord?.client?.id ?? "" }}
                                    perPage={99999}
                                    sort={{ field: "name", order: "ASC" }}
                                    value={slotDetails?.product?.id || ""}
                                    fullWidth
                                >
                                    <AutocompleteInput optionText="name" onChange={handleProductChange} defaultValue={selectedProductId} fullWidth validate={[required("Please select a product")]} style={{ width: "100%" }} />
                                </ReferenceInput>
                            </Grid>
                        </Grid>
                        {selectedProductId && (
                            <ProductForm
                                productId={selectedProductId}
                                formValues={slotDetails}
                                dirtyFields={dirtyFields}
                                modified={modified}
                                machineMode={machineRecord?.machineMode}
                                clientId={machineRecord?.client?.id}
                                onMROAccessChange={handleMROAccessChange}
                                onProductRecordChange={handleProductRecordChange}
                            />
                        )}
                    </CardContent>
                    <CardActions style={{ width:"100%" }}>
                        <Box style={{ display: "flex", justifyContent:"space-between", alignItems:"center", width:"100%" }}>
                            <Box gap={2}>
                                <Button type="submit" variant="contained" color="primary" disabled={saving} style={{ marginRight:"1rem" }}>
                                    {saving ? "Saving" : "Save"}
                                </Button>
                                <Button onClick={() => navigate(`/machines/edit/${machineId}`)} variant="contained">
                                    Back
                                </Button>
                            </Box>
                            <Button onClick={onRemove} variant="contained" dsiabled={removing || saving} style={{ backgroundColor: "red", color: "#FFF" }}>
                                Remove
                            </Button>
                        </Box>
                    </CardActions>
                </SimpleForm>
            </_extendLayout>
        </>
    )
}

export default SlotEdit;
