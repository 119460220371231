import React from 'react';
import { isEmpty } from 'react-admin';
import { generatePath, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';

const SLOT_STATUS = {
    DISABLE: 0,
    DEFAULT: 1,
    ERROR: 2,
    REFILL: 3,
    OCCUPIED: 4,
};

const SlotItem = ({ slotNumber, colNumber, rowNumber, data, machineId, disable, is12Slots }) => {
    const { maxCapacity, totalQuantity, minCapacity, error, product } = data;

    const getStatus = () => {
        if(disable) return SLOT_STATUS.DISABLE;
        if(isEmpty(data) || Object.keys(data).length === 0) return SLOT_STATUS.DEFAULT;
        if(error) return SLOT_STATUS.ERROR;
        if(totalQuantity !== undefined && minCapacity !== undefined && totalQuantity <= 0) return SLOT_STATUS.REFILL;
        return SLOT_STATUS.OCCUPIED;
    };
    
    const navigate = useNavigate();
    const status = getStatus();

    const handleClick = () => {
        if(!disable) {
            const path = generatePath("/machines/:machineId/slots/:slotNumber", { machineId, slotNumber });
            navigate(path);
        }
    };

    if(disable) {
        return <div></div>;
    } else {
        return (
            <Box
                onClick={handleClick}
                className={`slot ${status === SLOT_STATUS.OCCUPIED ? "occupied" : status === SLOT_STATUS.ERROR ? "error" : status === SLOT_STATUS.REFILL ? "ofs" : "empty"}`}
            >
                <Typography variant="body2" className="slot-label">{colNumber}</Typography>
                <Typography variant="body1" fontWeight="bold" className="product-name" mt={1}>
                    {product ? product.name : "Add Product"}
                </Typography>
                <Typography variant="body2" className="quantity" mt={1}>
                    {maxCapacity ? `${totalQuantity}/${maxCapacity}` : "-"}
                </Typography>
            </Box>
        );
    }
};

export default SlotItem;
