import React, { useEffect, useState } from 'react'
import { AutocompleteInput, NumberInput, ReferenceInput, required, SimpleForm, useGetOne } from 'react-admin';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetBoardSlot, useGetSlot, useRemoveSlot, useSaveSlot } from './RefreshMachineStatus';
import { deleteDoc, doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { Box, Button, CardActions, CardContent, CardHeader, FormControlLabel, Grid, Switch } from '@material-ui/core';
import ProductForm from './ProductForm';
import _extendLayout from '../../layout/_extendLayout';

const quantityExceedCapacity = msg => (value, values) => {
    const { maxCapacity } = values;
    if(value > maxCapacity) {
        return msg || "Invalid";
    }
}

const SlotItemBoardEdit = () => {

    const { machineId, slotId, boardId } = useParams();
    
    const { data: machineRecord, loaded: machineLoaded } = useGetOne("machines", { id: machineId });
    const { data: slotRecord, loaded: slotLoaded } = useGetSlot(machineId, slotId);
    const { data: masterSchemaSlotData, loaded: masterSchemaLoaded } = useGetBoardSlot(machineId, boardId, slotId);
    const { saving, save } = useSaveSlot();
    const { removing, remove } = useRemoveSlot(machineId, slotId);
    const isCreate = slotRecord ? false : true;

    let dataToShow = null;
    dataToShow = (isCreate) ? masterSchemaSlotData : slotRecord;
    
    const [dropSensor, setDropSensor] = useState(false);
    const [error, setError] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState(dataToShow?.product?.id || null);

    const [mroAccess, setMROAccess] = useState([]);
    const [productRecord, setProductRecord] = useState(null);
    const [dirtyFields, setDirtyFields] = useState({});
    const [modified, setModified] = useState(false);
    
    const navigate = useNavigate();
    
    useEffect(() => {
        if(dataToShow) {
            setDropSensor(dataToShow.dropSensor ?? false);
            setError(dataToShow.error ?? false);
            setSelectedProductId(dataToShow?.product?.id ?? null);
        }
    }, [dataToShow]);

    const onSubmit = async (datas) => {
        const boardData = await getAddressByBoardId();

        let slotObj = {
            dropSensor: dropSensor,
            error: error,
            itemNumber: datas.itemNumber,
            maxCapacity: datas.maxCapacity,
            minCapacity: datas.minCapacity,
            priceCredit: datas.priceCredit,
            product: {
                id: datas.product.id,
                name: productRecord.name,
                category: productRecord.category,
                description: productRecord.description ?? "",
                image: {
                    src: productRecord.image.src ?? "",
                },
                mroAccessGroup: mroAccess,
                priceCredit: datas.priceCredit ?? 0,
                priceCash: datas.priceCash ?? 0,
            },
            totalQuantity: datas.totalQuantity,
        };

        // let boardObj = {
        //     dropSensor: dropSensor,
        //     error: error,
        //     machineAddress: boardData.address, //00 spiral, not confirm is 00
        //     machineSlot: parseInt(slotId), //slot number
        //     maxCapacity: datas.maxCapacity,
        //     minCapacity: datas.minCapacity,
        //     name: slotId, //slot number
        //     totalQuantity: datas.totalQuantity,
        //     type: boardData.type
        // };

        try{
            const machineSlotDocRef = doc(db, `machines/${machineId}/slots/${slotId}`);
            // const boardSlotDocRef = doc(db, `machines/${machineId}/boards/${boardId}/slots/${slotId}`);
    
            await getDoc(machineSlotDocRef);
            // await getDoc(boardSlotDocRef);
            
            await Promise.all([
                setDoc(machineSlotDocRef, slotObj, { merge: true }),
                // setDoc(boardSlotDocRef, boardObj, { merge: true }),
            ]);
    
            alert("Element Successfully inserted/updated.");
            navigate(`/machines/edit/${machineId}`)
        } catch (err) {
            console.error("Error handling Firebase operations:", err);
        }

    };

    const onRemove = async () => {
        try {
            const slotDocRef = doc(db, `machines/${machineId}/slots`, slotId);
            await deleteDoc(slotDocRef);

            alert(`Slot ${slotId} removed successfully.`);
            navigate(`/machines/edit/${machineId}`);
        } catch (error) {
            console.error("Error deleting slot:", error);
            alert("Failed to remove the slot. Please try again.");
        }
    };

    const handleOnChange = (formValues) => {
        const newDirtyFields = {};
        const currentData = dataToShow || {};
        Object.keys(formValues).forEach(key => {
            if(formValues[key] !== currentData[key]) {
                newDirtyFields[key] = true;
            }
        });
        setDirtyFields(newDirtyFields);
        setModified(Object.keys(newDirtyFields).length > 0);
    };

    const handleMROAccessChange = (updatedValues) => {
        setMROAccess(updatedValues);
    };

    const handleProductRecordChange = (newProductRecord) => {
        setProductRecord(newProductRecord); // Update state in SlotEdit
    };

    const handleProductChange = (value) => {
        setSelectedProductId(value);
    };

    const getAddressByBoardId = async () => {
        try {
            const boardDocRef = doc(db, `machines/${machineId}/boards/${boardId}`);
            const boardDoc = await getDoc(boardDocRef);
            if(boardDoc.exists()) {
                const boardData = boardDoc.data()
                return boardData;
            }
        } catch (error) {
            console.error("Error retrieving board address:", error);
        }
    };
    
    return (
        <>
            <_extendLayout>
                <CardHeader title={`Edit Slot ${slotId}`} />
                <CardHeader title="Slot Details" />
                <SimpleForm
                    toolbar={null} 
                    initialvalues={{
                        ...dataToShow,
                    }} 
                    onChange={handleOnChange} 
                    onSubmit={onSubmit}
                >
                    <CardContent style={{ width: "100%" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <NumberInput
                                    label="Item number (display to user)"
                                    source="itemNumber"
                                    fullWidth
                                    validate={required("Please enter an item number")}
                                    defaultValue={dataToShow?.itemNumber}
                                />
                                <input type="hidden" name="machineSlotId" value={parseInt(slotId)} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <NumberInput
                                    source="maxCapacity"
                                    fullWidth
                                    validate={required("Please enter a max capacity number.")}
                                    defaultValue={dataToShow?.maxCapacity}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <NumberInput
                                    label="Current Quantity"
                                    source="totalQuantity"
                                    fullWidth
                                    validate={[
                                        required("Please enter a quantity number."),
                                        quantityExceedCapacity("Quantity cannot exceed the max capacity.")
                                    ]}
                                    defaultValue={dataToShow?.totalQuantity}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <NumberInput
                                    label="Min Capacity"
                                    source="minCapacity"
                                    fullWidth
                                    validate={[
                                        quantityExceedCapacity("Quantity cannot exceed the max capacity.")
                                    ]}
                                    defaultValue={dataToShow?.minCapacity}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControlLabel
                                    label="Drop Sensor"
                                    control={
                                        <Switch
                                            checked={dropSensor}
                                            onChange={(e) => setDropSensor(e.target.checked)}
                                        />
                                    }
                                />
                                <FormControlLabel
                                    label="Slot Lock"
                                    control={
                                        <Switch
                                            checked={error}
                                            onChange={(e) => setError(e.target.checked)}
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </CardContent>

                    <CardHeader title="Select Product" />
                    <CardContent style={{ width: "100%" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} style={{ width: "100%" }}>
                                <ReferenceInput
                                    label="Product"
                                    source="product.id"
                                    reference="products"
                                    filterToQuery={() => {}}
                                    filter={machineRecord?.client?.id ? { client: machineRecord.client.id } : {}}
                                    perPage={50}
                                    sort={{ field: "name", order: "ASC" }}
                                    value={dataToShow?.product?.id || ""}
                                    fullWidth
                                >
                                    <AutocompleteInput optionText={(record) => `${record.name}`} onChange={handleProductChange} defaultValue={selectedProductId} fullWidth validate={[required("Please select a product")]} style={{ width: "100%" }} />
                                </ReferenceInput>
                            </Grid>
                        </Grid>
                        {selectedProductId && (
                            <ProductForm
                                productId={selectedProductId}
                                formValues={dataToShow}
                                dirtyFields={dirtyFields}
                                modified={modified}
                                machineMode={machineRecord?.machineMode}
                                clientId={machineRecord?.client?.id}
                                onMROAccessChange={handleMROAccessChange}
                                onProductRecordChange={handleProductRecordChange}
                            />
                        )}
                    </CardContent>
                    <CardActions style={{ width:"100%" }}>
                        <Box style={{ display: "flex", justifyContent:"space-between", alignItems:"center", width:"100%" }}>
                            <Box gap={2}>
                                <Button type="submit" variant="contained" color="primary" disabled={saving} style={{ marginRight:"1rem" }}>
                                    {saving ? "Saving" : "Save"}
                                </Button>
                                <Button onClick={() => navigate(`/machines/edit/${machineId}`)} variant="contained">
                                    Back
                                </Button>
                            </Box>
                            <Button onClick={onRemove} variant="contained" dsiabled={removing || saving} style={{ backgroundColor: "red", color: "#FFF" }}>
                                Remove
                            </Button>
                        </Box>
                    </CardActions>
                </SimpleForm>
            </_extendLayout>
        </>
    )
}

export default SlotItemBoardEdit;
