import { Button, Menu, MenuItem } from '@material-ui/core';
import React, { useState } from 'react';
import MoreIcon from "@material-ui/icons/ExpandMoreOutlined";
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider, useNotify, useRecordContext, useRedirect } from 'react-admin';
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

const ActionMenu = ({ resource, companyId }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = e => setAnchorEl(e.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const record = useRecordContext();;
    const navigate = useNavigate();
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();

    //get latest user list
    const fetchUserData = async () => {
        try {
            const res = await dataProvider.getList("users", {
                filter: { "client.id": companyId },
                sort: { field: "createdAt", order: "DESC" },
                pagination: { page: 1, perPage: 1000 },
            });
        } catch (err) {
            console.log(err)
        }
    }

    //handle edit action
    const handleEdit = () => {
        const editUrl = `/users/edit/${record.id}`;
        redirect(editUrl);
    };

    //handle disbale actions
    const handleDisable = () => {
        const newStatus = record.status == "active" ? "disabled" : "active";
        dataProvider.update(resource, { id: record.id, data: { ...record, status: newStatus } })
            .then(() => {
                notify(`User has been ${record.status ? 'enabled' : 'disabled'}`, { type: 'info' });
                setAnchorEl(null);
            })
            .catch(err => {
                notify(`Error: ${err.message}`, { type: 'warning' });
            })
    };

    //handle reset password
    const handleResetPassword = async () => {
        console.log("in")
        console.log("id: ", record.email)
        try{
            const auth = getAuth();
            const user = auth.currentUser;
            if(user) {
                const token = await user.getIdToken(true);
                console.log("User token: ", token);

                const functions = getFunctions(undefined, "asia-east2");
                const resetPwd = httpsCallable(functions, "auth-resetPasswordV2"); // Call the callable function
                console.log("frontend user email: ",record.email)
                // Call the function with the user's email
                const result = await resetPwd({ email: record.email });
                
                console.log("Cloud Function Result: ", result);
            } else {
                console.log("log in first")
            }
        } catch(err) {
            console.log("error: ", err);
        }
    };

    const handleDelete = async () => {
        try {
            if (record && record.id) {
                console.log("Welcome to delete this: " + record.id);
                
                //integrate with cloud function
                const functions = getFunctions(undefined, "asia-east2");
                const deleteRes = httpsCallable(functions, "auth-deleteUserV2");

                await deleteRes({ id: record.id });
                console.log("Deleted user");
                //fetch lastest users
                const updatedUsers = await fetchUserData();
                setAnchorEl(null);
                //redirect to /users page
                navigate("/users", { state: { users: updatedUsers } });
                console.log("redirected to users page")
            } else {
                console.error("No record found to delete.");
            }
        } catch(error) {
            console.log("Delete button error: ", error)
        };
    };

    return (
        <>
            <Button aria-haspopup="true" onClick={handleClick} size='small'>
                More <MoreIcon />
            </Button>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={handleEdit} className={classes.menuItem}>
                    {record && (
                        <Button
                            label="Edit"
                            className={classes.actionButton}
                        >Edit</Button>
                    )}
                </MenuItem>
                <MenuItem onClick={handleDisable} className={classes.menuItem}>
                    <Button
                        size="small"
                        className={classes.actionButton}
                        color={record.status ? "primary" : "secondary"}
                    >
                        {record.status == "active" ? 'disabled' : "enable"}
                    </Button>
                </MenuItem>
                <MenuItem onClick={handleResetPassword} className={classes.menuItem}>
                    <Button
                        size="small"
                        className={classes.actionButton}
                    >
                        Reset Password
                    </Button>
                </MenuItem>
                <MenuItem className={classes.menuItem} onClick={handleDelete}>
                    {record && record.id && (
                        <Button
                            size="small"
                            className={`${classes.actionButton}`}
                            style={{ padding:"6px 16px", color: "red" }}
                        >
                            Delete
                        </Button>
                    )}
                </MenuItem>
            </Menu>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    icon: {
      marginLeft: theme.spacing(0.5),
      width: 20,
    },
    menuItem: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    actionButton: {
      justifyContent: 'flex-start',
      paddingTop: 6,
      paddingBottom: 6,
      paddingLeft: 16,
      paddingRight: 16,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    customDelete: {
        color: 'red',
    }
}));

export default ActionMenu;
