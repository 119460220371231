import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from "chart.js";

import "../components/css/SalesTrend.css";

ChartJS.register(ArcElement, Title, Tooltip, Legend);

const SalesTrend = () => {
    const chartColors = {
        red: getComputedStyle(document.documentElement).getPropertyValue("--chart-color-red").trim(),
        blue: getComputedStyle(document.documentElement).getPropertyValue("--chart-color-blue").trim(),
        yellow: getComputedStyle(document.documentElement).getPropertyValue("--chart-color-yellow"),
        green: getComputedStyle(document.documentElement).getPropertyValue("--chart-color-green").trim(),
        purple: getComputedStyle(document.documentElement).getPropertyValue("--chart-color-purple").trim(),
        orange: getComputedStyle(document.documentElement).getPropertyValue("--chart-color-orange").trim(),
        borderRed: getComputedStyle(document.documentElement).getPropertyValue("--chart-color-border-red").trim(),
        borderBlue: getComputedStyle(document.documentElement).getPropertyValue("--chart-color-border-blue").trim(),
        borderYellow: getComputedStyle(document.documentElement).getPropertyValue("--chart-color-border-yellow").trim(),
        borderGreen: getComputedStyle(document.documentElement).getPropertyValue("--chart-color-border-green").trim(),
        borderPurple: getComputedStyle(document.documentElement).getPropertyValue("--chart-color-border-purple").trim(),
        borderOrange: getComputedStyle(document.documentElement).getPropertyValue("--chart-color-border-orange").trim(),
    };
    const data = {
        labels: ["Beverages", "Snacks", "Candy", "Foods"],
        datasets: [
            {
                label: "Colors",
                data: [300, 50, 100, 200], //category value
                backgroundColor: [
                    chartColors.red,
                    chartColors.blue,
                    chartColors.yellow,
                    chartColors.green,
                ],
                borderColor: [
                    chartColors.borderRed,
                    chartColors.borderBlue,
                    chartColors.borderYellow,
                    chartColors.borderGreen,
                ],
                borderWidth: 1,
            }
        ]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                onClick: (e) => e.native.stopPropagation(),
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const data = tooltipItem.dataset.data;
                        const total = data.reduce((acc, value) => acc + value, 0);
                        const currentValue = data[tooltipItem.dataIndex];
                        const percentage = ((currentValue / total) * 100).toFixed(2);
                        return `${tooltipItem.label}: ${percentage}%`;
                    },
                },
            },
        },
    }

    return(
        <>
            <div className="salesTrendChart">
                <div>
                    <h6 className="lblSalesChart">Sales Chart</h6>
                </div>

                <div className="chart-wrapper">
                    <Pie data={ data } options={ options } />
                </div>
            </div>
        </>
    )
};

export default SalesTrend;