import { collection, doc, getDocs, getDoc, updateDoc, deleteDoc, addDoc, query, where, orderBy, limit, startAfter, getCountFromServer } from "firebase/firestore";
import { db } from "./firebase";


const firebaseDataProvider = {
    getList: async (resource, params) => {
        const { filter, pagination, sort } = params;
        const { page, perPage } = pagination;

        const collectionRef = collection(db, resource);
        let q = query(collectionRef);

        //apply filter
        if(filter) {
            Object.keys(filter).forEach(key => {
                if(filter[key]) {
                    q = query(q, where(key, "==", filter[key]));
                }
            });
        }

        //apply sorting
        if(sort && sort.field && sort.order) {
            const { field, order } = sort;
            q = query(q, orderBy(field, order.toLowerCase()));
        }

        //get total count
        const totalSnapshot = await getCountFromServer(q);
        const total = totalSnapshot.data().count;

        //apply pagination
        if(page > 1) {
            const offset = (page - 1) * perPage;
            const previousPageSnapshot = await getDocs(query(q, limit(offset)));
            const lastVisible = previousPageSnapshot.docs[previousPageSnapshot.docs.length - 1];
            q = query(q, startAfter(lastVisible), limit(perPage));
        } else {
            q = query(q, limit(perPage));
        }

        const querySnapshot = await getDocs(q);
        const data = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));

        return {
            data,
            total, // Total count before pagination
        };
    },
    getOne: async (resource, params) => {
        const docRef = doc(db, resource, params.id);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
            return { data: { id: docSnap.id, ...docSnap.data() } };
        } else {
            throw new Error('Document not found');
        }
    },
    create: async (resource, params) => {
        const collectionRef = collection(db, resource);
        const newDoc = await addDoc(collectionRef, params.data);
        
        return {
            data: { ...params.data, id: newDoc.id },
        };
    },
    update: async (resource, params) => {
        const docRef = doc(db, resource, params.id);
        await updateDoc(docRef, params.data);
        
        return {
            data: { ...params.data, id: params.id },
        };
    },
    delete: async (resource, params) => {
        const docRef = doc(db, resource, params.id);
        await deleteDoc(docRef);
        
        return { data: { id: params.id } };
    },
    getMany: async (resource, params) => {
        const docs = await Promise.all(
            params.ids.map(id => getDoc(doc(db, resource, id)))
        );
        const data = docs.map(docSnap => ({
            id: docSnap.id,
            ...docSnap.data(),
        }));
        
        return { data };
    },
}

export default firebaseDataProvider;
