import { Button, CardHeader } from '@material-ui/core';
import { onAuthStateChanged } from 'firebase/auth';
import React, { useCallback, useEffect, useState } from 'react'
import { Datagrid, ExportButton, List, NumberField, Pagination, ReferenceField, TextField, TopToolbar, useDataProvider } from 'react-admin';
import { auth } from '../../firebase';
import TransactionFilter from '../Filters/TransactionFilter';
import { FilterList as FilterListIcon } from '@mui/icons-material';
import _extendLayout from '../../layout/_extendLayout';

const TransactionProductList = ({ clientField = "client" }) => {
    const dataProvider = useDataProvider();

    const [isAdmin, setIsAdmin] = useState(false);
    const [proList, setProList] = useState([]);
    const [clientId, setClientId] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [groupedData, setGroupedData] = useState([]);

    const roundDecimal = (value) => Math.round(value * 100) / 100;

    useEffect(() => {
        const checkAuth = onAuthStateChanged(auth, async (user) => {
            if(user) {
                const { filter: currentFilter, admin} = await getCurrentUser(user.uid);
                await getTransactionList(currentFilter, admin);
            }
        });

        return () => checkAuth();
    }, []);

    const getCurrentUser = async (userId) => {
        try {
            const res = await dataProvider.getOne("users", { id: userId });

            const admin = res.data.role === "admin";
            setIsAdmin(admin);

            let currentFilter = admin
                ? { mode: "mro" }
                : {
                    [clientField]: res.data?.client?.id,
                    mode: "mro",
                    status: 1
                };
            
            if(!admin) setClientId(res.data?.client?.id);
            
            return { filter: currentFilter, admin };
        } catch (err) {
            console.log(err);
        }
    };

    const getTransactionList = async (filter, admin) => {
        try {
            const res = await dataProvider.getList("transactions", { filter, pagination: { page: 1, perPage: 1000 }, });
            
            const grouped = groupByProduct(res.data);
            setGroupedData(grouped);
        } catch (err) {
            console.log(err);
        }
    };

    const groupByProduct = (transactions) => {
        const grouped = transactions.reduce((acc, transaction) => {
            const productName = transaction.product?.name;
            if (!productName) return acc;

            if (!acc[productName]) {
                acc[productName] = {
                    product: transaction.product,
                    totalQty: 0,
                    totalCredit: 0,
                };
            }

            acc[productName].totalQty += transaction.quantity || 0;
            acc[productName].totalCredit += transaction.amount || 0;

            return acc;
        }, {});

        return Object.values(grouped);
    };
    
    const handleModalOpenChange = useCallback((isOpen) => {
            setIsModalOpen(isOpen);
    }, []);

    const hanldeFilteredData = (newFilteredData) => {
        setFilteredData(newFilteredData)
    };

    const proExporter = async (pro) => {
        
        const headers = [
            "ID",
            "Client",
            "Product Category ID",
            "Product Name",
            "Product Price",
            "Product Slot ID",
            "Total Quantity",
            "Total Transactions",
            "Total Cash",
            "Total Credit",
        ];

        let csvRows = [headers.join(",")];

        const filterData = pro.filter((e) => e.client === clientId);

        const groupedData = filterData.reduce((acc, e) => {
            const productId = e.product?.id;
            if(!productId) return acc;

            if(!acc[productId]) {
                acc[productId] = {
                    id: e.id,
                    client: e.client,
                    product: e.product,
                    totalQty: 0,
                    totalCash: 0,
                    totalCredit: 0,
                    totalTransactions: 0,
                };
            }

            const { quantity = 0, amount = 0, mode } = e;

            acc[productId].totalQty += quantity;
            acc[productId].totalTransactions += 1;
            if(mode === "commercial") {
                acc[productId].totalCash += amount;
            } else if (mode === "mro") {
                acc[productId].totalCredit += amount;
            }

            return acc;
        }, {});

        Object.values(groupedData).forEach((e) => {
            const commonDetails = [
                e.id,
                e.client ?? "",
                e.product?.categoryId ?? "",
                e.product?.name ?? "",
                e.product?.price ?? "",
                e.product?.slot ?? "",
                e.totalQty, //total qty
                e.totalTransactions, //total transactions
                e.totalCash, //total cash
                e.totalCredit, //total credit
            ];
            csvRows.push([...commonDetails].join(","));
        });

        const csvContent = csvRows.join("\n");
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "MRO Report Products.csv";
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const CustomActions = React.memo(({ ...props }) => (
        <TopToolbar style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", flex: 1 }}>
                {}
            </div>
            <Button
                startIcon={<FilterListIcon />}
                onClick={() => props.onShowFilters()}
                variant="outlined"
                color="primary"
                style={{ border: "none" }}
            >
                Show Filters
            </Button>
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <TransactionFilter
                    setFilteredData={props.setFilteredData}
                    allData={props.data}
                    onModalOpenChange={handleModalOpenChange}
                    modalOpen={isModalOpen}
                />
                <ExportButton label="Export" {...props} exporter={props.proExporter} />
            </div>
        </TopToolbar>
    ));
    
    return (
        <>
            <_extendLayout isModalOpen={isModalOpen}>
                <CardHeader title="MRO List By Product" />
                <div style={{ width: '90%', margin: '0 auto', padding: '20px' }}>
                    <List
                        resource="transactions"
                        filter={{ "client": clientId, mode: "mro", status: 1 }}
                        sort={{ field: 'date', order: 'DESC' }}
                        perPage={25}
                        filters={<TransactionFilter searchKeys={null} />}
                        actions={
                            <CustomActions
                                onShowFilters={() => setIsModalOpen(true)} // Open the Drawer
                                handleModalOpenChange={handleModalOpenChange} // Pass callback for modal state
                                isModalOpen={isModalOpen}
                                setFilteredData={setFilteredData}
                                data={proList}
                                proExporter={proExporter}
                            />
                        }
                    >
                        <Datagrid data={groupedData} rowClick="show" bulkActionButtons={false} sx={{ '& .RaDatagrid-headerCell': { fontWeight: 'bold' } }}>
                            <TextField label="Product Name" source="product.name" />
                            {isAdmin && (
                                <ReferenceField label="Company" source="client" reference="clients" link={false}>
                                    <TextField source="companyName" />
                                </ReferenceField>
                            )}
                            <TextField label="Total QTY Sold" source="totalQty" options={{ style: 'decimal' }} />
                            <NumberField label="Total Revenue (Credit)" source="totalCredit" />
                        </Datagrid>
                    </List>
                </div>
            </_extendLayout>
        </>
    );
}

export default TransactionProductList;
