import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import { auth, db } from '../../firebase';
import { SelectInput, useDataProvider, required, ReferenceInput, SimpleForm, TextInput, BooleanInput, NumberInput, minValue } from 'react-admin';
import { Button, CardActions, CardHeader, CardContent, Grid } from '@material-ui/core';
import { addDoc, collection } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import _extendLayout from '../../layout/_extendLayout';

const EmployeeCreate = () => {
    const dataProvider = useDataProvider();
    const navigate = useNavigate();

    const [isAdmin, setIsAdmin] = useState(false);
    const [clientId, setClientId] = useState(null);
    const [client, setClient] = useState("");
    const [creditValue, setCreditValue] = useState(null);
    const [recurringCredit, setRecurringCredit] = useState(0);
    const [recurringCreditType, setRecurringCreditType] = useState("none");
    const [typeName, setTypeName] = useState(null);
    const [paramsList, setParamsList] = useState([{ tag: "", value: "" }]);
    

    useEffect(() => {
        const checkAuth = onAuthStateChanged(auth, async (user) => {
            if(user) {
                getCurrentUser(user.uid);
            }
            return () => checkAuth();
        });
    }, [auth]);

    const getCurrentUser = async (userId) => {
        try {
            const res = await dataProvider.getOne("users", { id: userId });
            setClientId(res.data.client.id);
            setIsAdmin(res.data.role == "admin");
        } catch (err) {
            console.log("error is ", err);
        }
    };

    const getTypeValue = async (e) => {
        try {
            let value = e.target.value;
            const res = await dataProvider.getOne("employeeTypes", { id: value });
            setTypeName(res.data.name.trim());
            setCreditValue(res.data);
        } catch(err) {
            console.log("error: ", err);
        }
    };

    const handleAddInputField = () => {
        setParamsList([...paramsList, { tag: "", value: "" }]);
    };

    const handleClientChange = (newClient) => {
        setClient(newClient)
    };

    const handleRemoveInputField = (index) => {
        const list = [...paramsList];
        list.splice(index, 1);
        setParamsList(list);
    };

    const handleInputOnChange = (e, index, field) => {
        const updatedParamsList = [...paramsList];
        updatedParamsList[index] = {
            ...updatedParamsList[index],
            [field]: e.target.value,
        }
        setParamsList(updatedParamsList);
    };

    const handleRecurringCreditTypeChange = (e) => {
        setRecurringCreditType(e.target.value);
    };

    const getEmployeeDetails = async () => {
        try{
            const res = await dataProvider.getList("employees", {
                filter: { client: clientId },
                pagination: { page: 1, perPage: 1000 },
            });

            if(res.data.length > 0) {
                return res.data;
            } else {
                return null;
            }
        } catch (err) {
            console.log("error: ", err);
        }
    };

    const handleSave = async (data) => {
        const preData = await getEmployeeDetails();

        let dupScoreCar = 0;
        let dupScoreEmp = 0;

        preData.map(m => {
            if(m.cardNumber === data.cardNumber) dupScoreCar++;
            if(data.employeeNumber && m.employeeNumber === data.employeeNumber) dupScoreEmp++;
        });

        if(typeof data.credit !== "number" || data.credit < 0) {
            alert("Credit can only be a number and cannot be less than 0.");
            return;
        }

        if(data.recurringCreditType === "none") data.recurringCredit = 0;
        if(!data.employeeNumber) data.employeeNumber = "";

        if(dupScoreEmp !== 0) {
            alert("Employee Number is taken");
            return;
        }

        if(dupScoreCar !== 0) {
            alert("Card Number is taken");
            return;
        }

        let emp = {
            cardNumber: data.cardNumber,
            client: (isAdmin) ? data.client : clientId,
            costCenter: data.costCenter || "",
            createdAt: new Date(),
            credit: data.credit,
            email: data.email || "",
            employeeNumber: data.employeeNumber,
            mobile: data.mobile || "",
            fullName: data.fullName,
            isActive: data.isActive,
            params: paramsList,
            recurringCredit: data.recurringCredit,
            recurringCreditType: data.recurringCreditType,
            type: {
                id: data.type,
                name: typeName,
            },
            updatedAt: new Date(),
        }

        await addDoc(collection(db, "employees"), emp);
        alert("Employee created")
        navigate("/employees");
    };

    return (
        <>
            <_extendLayout>
                <CardHeader title="Create a Employee" />
                <CardContent>
                    <SimpleForm toolbar={null} onSubmit={handleSave}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextInput
                                    label="Full Name"
                                    name="fullName"
                                    fullWidth
                                    validate={required("Please enter a full name")}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput
                                    label="Card Number"
                                    name="cardNumber"
                                    fullWidth
                                    validate={required("Please enter a card number")}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput
                                    label="Employee Number"
                                    name="employeeNumber"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput
                                    label="Email"
                                    name="email"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput
                                    label="Mobile"
                                    name="mobile"
                                    fullWidth
                                />
                            </Grid>
                            {isAdmin ? (
                                <Grid item xs={12}>
                                    <ReferenceInput
                                        source="client"
                                        reference="clients"
                                        filterToQuery={() => {}}
                                        onChange={(e) => handleClientChange(e.target.value)}
                                    >
                                        <SelectInput
                                            optionText="companyName"
                                            variant="outlined"
                                            fullWidth
                                            margin="none"
                                            validate={required("Please select a client")}
                                        />
                                    </ReferenceInput>
                                </Grid>
                            ) : (
                                <input type='hidden' name="client" value={client} />
                            )}
                            {clientId && (
                                <Grid item xs={12} sm={12} md={12}>
                                    <ReferenceInput
                                        label="Type"
                                        source="type"
                                        reference="employeeTypes"
                                        fullWidth   
                                        filterToQuery={() => {}}
                                        filter={{ client: clientId }}
                                    >
                                        <SelectInput
                                            optionText="name"
                                            variant="outlined"
                                            fullWidth
                                            margin="none"
                                            validate={required("Please select a type")}
                                            onChange={(e) => getTypeValue(e)}
                                        />
                                    </ReferenceInput>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <TextInput
                                    label="Cost Center"
                                    name="costCenter"
                                    fullWidth
                                />
                            </Grid>
                            {paramsList.length > 0 && 
                                paramsList.map((item, index) => (
                                    <Grid container item xs={12} spacing={3} key={index}>
                                        <Grid item xs={12} md={5}>
                                            <TextInput
                                                label="Tag"
                                                name={`tag-${index}`}
                                                value={item.tag}
                                                fullWidth
                                                onChange={e => handleInputOnChange(e, index, "tag")}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <TextInput
                                                label="Value"
                                                name={`value-${index}`}
                                                value={item.value}
                                                fullWidth
                                                onChange={e => handleInputOnChange(e, index, "value")}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <Button
                                                style={{ width: "100%", height: 56 }}
                                                variant="contained"
                                                onClick={() => handleRemoveInputField(index)}
                                            >Remove</Button>
                                        </Grid>
                                    </Grid>
                                ))
                            }
                            {creditValue !== undefined && creditValue !== null && (
                                <Grid item xs={12}>
                                    <NumberInput
                                        label="Current Credit"
                                        source="credit"
                                        fullWidth
                                        validate={[
                                            required("Please enter a credit"),
                                            minValue(0, "Credit cannot be lower than 0."),
                                        ]}
                                        defaultValue={Math.floor(creditValue.credit * 100) / 100}
                                        onChange={(e) => 
                                            setCreditValue(pre => ({ ...pre, credit: Number(e.target.value) }))}
                                        sx={{ "& .MuiInputBase-input": { height: "35px", padding: "8px 12px" } }}
                                    />
                                    <input type="hidden" name="creditValue.name" value={creditValue.name} />
                                </Grid>
                            )}
                            <Grid item xs={12} md={6} mt={3}>
                                <SelectInput
                                    variant="outlined"
                                    fullWidth
                                    margin="none"
                                    source="recurringCreditType"
                                    label="Recurring Credit Type"
                                    reference="recurringCreditType"
                                    choices={[
                                        { id: "none", name: "None" },
                                        { id: "clearOff", name: "Clear Off" },
                                        { id: "cumulativeCredit", name: "Cumulative Credit" },
                                    ]}
                                    value={recurringCreditType}
                                    onChange={handleRecurringCreditTypeChange}
                                    defaultValue="none"
                                />
                            </Grid>
                            {recurringCreditType !== "none" && recurringCreditType !== undefined && (
                                <Grid item xs={12} md={6}>
                                    <NumberInput
                                        label="Recurring Credit"
                                        name="recurringCredit"
                                        value={recurringCredit}
                                        fullWidth
                                        onChange={(e) => {setRecurringCredit(Number(e.target.value))}}
                                        validate={[
                                            required("Please enter your reccurring credit."),
                                            minValue(0.01, "The minimum recurring credit must be more than 0.")
                                        ]}
                                        sx={{ "& .MuiInputBase-input": { height: "35px", padding: "8px 12px" } }}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12} md={6}>
                                <BooleanInput source="isActive" label="Active" defaultValue={true} />
                            </Grid>
                            <input type="hidden" name="params" value={paramsList} />
                            <Grid item xs={12} md={12}>
                                <Button variant="contained" color="primary" onClick={handleAddInputField}>
                                    Add Tag
                                </Button>
                            </Grid>
                        </Grid>
                        <CardActions>
                            <Button type="submit" variant="contained" color="primary">
                                Save
                            </Button>
                        </CardActions>
                    </SimpleForm>
                </CardContent>
            </_extendLayout>
        </>
    )
}

export default EmployeeCreate;