import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import { CiBellOn } from "react-icons/ci";
import { Menu, MenuItem } from "react-pro-sidebar";
import { FaBars } from 'react-icons/fa';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../components/css/NavBar.css';

const NavBar = ({ toggleNotificationList, broken, toggledMoibleSidebar, unReadCount }) => {
    return (
        <>
            <Navbar bg="light" expand="lg" style={{ width: '100%' }}>
                <div className="burger">
                    <div className="burger-icon">
                        {broken && (
                        <Menu iconShape="circle">
                            <MenuItem icon={<FaBars />} onClick={toggledMoibleSidebar}></MenuItem>
                        </Menu>
                            
                        )}
                    </div>
                    <div style={{ display: "flex", alignItems:"center", minHeight:'100%'}}>
                        <div className="notifications" onClick={toggleNotificationList}>
                            <div className="icon_wrap">
                                <CiBellOn size={40} />
                                {unReadCount > 0 && unReadCount <= 9 ? (
                                    <span className="notification-badge">{unReadCount}</span>
                                ) : unReadCount <= 0 ? (
                                    null
                                ) : (
                                    <span className="notification-badge">9+</span>
                                )}
                            </div>
                        </div>
                        {/* <div className="lblUser">
                            hello
                        </div> */}
                        {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <AccountCircleIcon />
                        </Avatar> */}
                        <div style={{ paddingLeft: "10px" }}>
                            <LogoutIcon />
                        </div>
                    </div>
                </div>
            </Navbar>
        </>
    )
}

export default NavBar;