import { collection, doc, getDoc, getDocs, query, serverTimestamp, where } from "firebase/firestore";
import { isEmpty } from "react-admin";
import { db } from "../../firebase";
import { Typography } from "@material-ui/core";

export const checkField = (tempField, consoleError) => {
    const expectedFields = [
        'id',
        'fullName',
        'cardNumber',
        'employeeNumber',
        'credit',
        'recurringCredit',
        'type.id',
        'type.name',
        'email',
        'mobile',
        'isActive',
        'createdAt',
        'updatedAt',
        'recurringCreditType',
        'costCenter'
    ];

    const field = tempField[0];

    if(field.length > expectedFields.length) {
        consoleError.push("Cannot add extra columns in this document");
    }

    expectedFields.forEach((expectedField, index) => {
        if (field[index] !== expectedField) {
          consoleError.push(`Column ${index + 1} should be ${expectedField}`);
        }
    });

    return consoleError;
};

export const randomIdMaker = (length = 28) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    return Array.from({ length }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join("");
};

export const convert2string = errors => errors.join("; ");

export const csv2json = async (clientId, tempResult, consoleError) => {
    const jsonObjectId = [];
    const jsonObjectArray = [];
    let count = 2;

    const employeeTypeList = await getEmployeeType(clientId);

    const validators = {
        checkName: (name) => {
            if(isEmpty(name)) {
                consoleError.push(`row ${count} column B: name cannot be left empty`);
                return "";
            }
            return name;
        },
        checkCard: (cardNumber) => {
            if(isEmpty(cardNumber)) {
                consoleError.push(`row ${count} column C: card number cannot be left empty`)
                return "";
            } else if (isNaN(cardNumber)) {
                consoleError.push(`row ${count} column C: card number can only contain digits`)
                return "";
            }
            return cardNumber;
        },
        checkCredit: (credit) => {
            if(isEmpty(credit)) {
                consoleError.push(`row ${count} column E: credit should not be empty`)
                return "";
            } else if (isNaN(credit)) {
                consoleError.push(`row ${count} column E: credit should be all digits`);
                return "";
            }
            return Number(credit);
        },
        checkRecurringCredit: (recurringCredit) => {
            if(isNaN(recurringCredit)) {
                consoleError.push(`row ${count} column F: Recurring credit should be all digits`);
                return "";
            }
            return Number(recurringCredit);
        },
        checkTypeId: (typeId, typeName) => {
            if(!isEmpty(typeId)) return typeId;
            const idFirestore = employeeTypeList.find(employee => employee.type_name === typeName);
            if(!idFirestore) consoleError.push(`row ${count} column G: type.id not found.`);
            return idFirestore ? idFirestore.type_id : "";
        },
        checkTypeName: (typeName) => {
            if(!isEmpty(typeName)) return typeName;
            consoleError.push(`row ${count} column H: type.name cannot be empty.`);
            return "";
        }
    };

    tempResult.forEach(result => {
        const jsonObject = {
            fullName: validators.checkName(result[1]),
            cardNumber: validators.checkCard(result[2]),
            employeeNumber: result[3],
            credit: validators.checkCredit(result[4]),
            recurringCredit: validators.checkRecurringCredit(result[5]),
            client: clientId,
            type: {
                id: validators.checkTypeId(result[6], result[7]),
                name: validators.checkTypeName(result[7]),
            },
            email: result[8],
            mobile: result[9],
            isActive: true,
            updatedAt: serverTimestamp(),
            recurringCreditType: result[13],
            costCenter: result[14],
        };

        jsonObjectId.push(result[0].toString());
        jsonObjectArray.push(jsonObject);
        count++;
    });
    return [jsonObjectId, jsonObjectArray, consoleError];
};

export const getCompanyId = async (clientId) => {
    try {
        const userDoc = doc(db, "users", clientId);
        const snapshot = await getDoc(userDoc);

        if(snapshot.exists()) {
            const data = snapshot.data();
            return data?.client?.id || null;
        } else {
            console.error('No such document!');
            return null;
        }

    } catch(err) {
        console.error('Error getting document:', err);
        return "";
    }
};

export const productType = async (post) => {
    const productId = post.product?.categoryId;

    if (!productId) {
        post.productTypeName = "Invalid Product ID";
        return post;
    }

    try {
        const productDocRef = doc(db, "categories", productId);
        const getProductTypeSnap = await getDoc(productDocRef);

        if(getProductTypeSnap.exists()) {
            const productType = getProductTypeSnap.data().name;
            post.productTypeName = productType;
        } else {
            post.productTypeName = "Product Type Not Found";
        }
    } catch (err) {
        console.log("Error fetching product type:", err);
        post.productTypeName = "Error Fetching Product Type";
    }

    return post;
};

export const renderDate = (record) => {
    
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const date = record?.date;
    
    if (date instanceof Date) {
        const time = `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
        return time;
    }

    if(date?.seconds) {
        const jsDate = new Date(date.seconds * 1000);
        const time = `${jsDate.getDate()} ${months[jsDate.getMonth()]} ${jsDate.getFullYear()}`;
        return time;
    }

    try {
        const parsedDate = new Date(date);
        if (!isNaN(parsedDate.getTime())) {
            const time = `${parsedDate.getDate()} ${months[parsedDate.getMonth()]} ${parsedDate.getFullYear()}`;
            return time;
        }
    } catch (err) {
        console.error("Error parsing date:", err);
    }

    return "N/A";
};

export const renderTime = record => {
    const time = record?.date;
    
    if(time) {
        let dateTime;
        if(time.seconds) {
            dateTime = new Date(time.seconds * 1000);
        } else if (typeof time === "string" || typeof time === "number") {
            dateTime = new Date(time);
        } else {
            dateTime = time;
        }

        if(dateTime instanceof Date && !isNaN(dateTime.getTime())) {
            const hours = String(dateTime.getHours()).padStart(2, "0");
            const minutes = String(dateTime.getMinutes()).padStart(2, "0");
            const seconds = String(dateTime.getSeconds()).padStart(2, "0");
            return `${hours}:${minutes}:${seconds}`;
        }
    }
    return "N/A";
};

export const getProductName = record => {
    const productName = record?.product?.name ?? null;
    if(productName === null) return "N/A";
    return productName;
};

export const getPrice = record => {
    const price = record?.amount ?? null;
    if(price === null) return "N/A";
    return price;
};

export const employeeType = async (post) => {
    const employeeId = post.employee?.id;
    
    if (!employeeId) {
        post.employeeTypeName = "";
        return post;
    };

    try {
        const employeeDocRef = doc(db, "employees", employeeId);
        const getEmployeeTypeSnap = await getDoc(employeeDocRef);
        
        if(getEmployeeTypeSnap.exists()) {
            const employeeType = getEmployeeTypeSnap.data();
            const getEmployeeType = employeeType?.type?.name;

            post.employeeTypeName = getEmployeeType ? getEmployeeType.toString() : "Type Not Defined";
            post.employee = employeeType;
        } else {
            post.employeeTypeName = "Employee Not Found";
            post.employee = "";
        }
    } catch (err) {
        console.log("Error fetching employee type:", err);
        post.employeeTypeName = "Error Fetching Employee Type";
        post.employee = "";
    }

    return post;
};

export const productDetails = async (post) => {
    const productId = post.product?.id;

    if (!productId) {
        post.productName = "Invalid Product ID";
        post.productSku = "Invalid Product ID";
        post.productSerial = "Invalid Product ID";
        return post;
    }

    try {
        const productDocRef = doc(db, "products", productId);
        const productSnapshot = await getDoc(productDocRef);

        if(productSnapshot.exists()) {
            const product = productSnapshot.data();
            const getName = product?.name;
            const getSku = product?.sku;
            const getSerial = product?.serialNumber;

            post.productName = getName ? getName.toString() : "";
            post.productSku = getSku ? getSku.toString() : "";
            post.productSerial = getSerial ? getSerial.toString() : "";
        } else {
            post.productName = "";
            post.productSku = "";
            post.productSerial = "";
        }

    } catch (err) {
        console.error("Error fetching product details:", err);
        post.productName = "Error fetching product details";
        post.productSku = "Error fetching product details";
        post.productSerial = "Error fetching product details";
    };

    return post;
};

export const getEmployeeType = async (companyId) => {
    try {
        const employeeTypeRef = collection(db, "employeeTypes");
        const q = companyId ? query(employeeTypeRef, where("client", "==", companyId)) : employeeTypeRef;
        const employeeSnapshot = await getDocs(q);

        const datas = employeeSnapshot.docs.map(doc => ({
            type_id: doc.id,
            type_name: doc.data().name,
        }));

        return datas;
    } catch (err) {
        console.error("Error fetching employee types:", err);
        return [];
    }
};

export const transactionStatus = (record) => {
    if(!record || record.status === null) return "Others";

    switch(record.status){
        case 1: 
            return "Successful";
        case 0:
            return "Failed";
        case -1: 
            return "Canceled";
        case -2:
            return "Dropped";
        case -3: 
            return "Void";
        default:
            return "Others";
    }
};

export const renderTransactionStatus = (record) => {
    if(!record || record.status === null || record.status === undefined) return <Typography style={{ fontSize: 12 }}>Others</Typography>;

    switch(record.status) {
        case 1:
            return <Typography style={{ color: "#00C07F", fontSize: 12 }}>Successful</Typography>;
        case 0: 
            return <Typography style={{ color: "#FF6562", fontSize: 12 }}>Failed</Typography>;
        case -1:
            return <Typography style={{ color: "#C0C0C0", fontSize: 12 }}>Canceled</Typography>;
        case -2:
            return <Typography style={{ color: "#FF6562", fontSize: 12 }}>Dropped</Typography>;
        case -3: 
            return <Typography style={{ color: "#FF6562", fontSize: 12 }}>Void</Typography>;
        default:
            return <Typography style={{ fontSize: 12 }}>Others</Typography>
    }
};

export const statusForExcelReport = status => {
    switch (status) {
        case 1:
            return "Successful";
        case 0:
            return "Failed";
        case -1: 
            return "Canceled";
        case -2:
            return "Dropped";
        case -3:
            return "Void";
    }
};

export const getTotalCashReceived = record => {
    const cashReceived = record?.cashInDetails?.cashInTotal ?? null;
    if(cashReceived === null) return "";
    return cashReceived;
};

export const getTotalCashReturned = record => {
    const cashReturned = record?.cashOutDetails?.cashOutTotal ?? null;
    if(cashReturned === null) return "";
    return cashReturned;
};

export const getTransId = record => {
    const transId = record?.payment?.transactionId ?? null;
    if(transId === null) return "";
    return transId;
};

export const getRefId = record => {
    const refNo = record?.payment?.refNo ?? null;
    if(refNo === null) return "";
    return refNo;
}

export const getPriceInTransaction = async (record) => {
    const priceInTransaction = record?.product?.price;

    if (priceInTransaction === null) {
        return "-";
    }

    return priceInTransaction;
};

export const renderProductSlot = record => {
    let rowArray = ["A", "B", "C", "D", "E", "F"];
    let productSlot = record?.product?.slot;
    if(productSlot === null) return "N/A";
    let row = Math.floor(productSlot / 12);
    let column = productSlot % 12;
    return `${rowArray[row]}${column}`;
};

const TRANSACTION = {
    GATEWAY: {
        '234': 'Alipay Scan',
        '305': 'WeChatPay CN Scan',
        '320': 'Boost Scan',
        '329': 'MCashMS',
        '336': 'TNG Scan',
        '338': 'UnionPay Scan',
        '347': 'GrabPay QR',
        '354': 'MaybankQR Scan',
        '379': 'GrabPay Scan',
        '164': 'PrestoPay Scan',
        '19': 'ShopeePay Scan',
    },
};

export const renderGatewayField = record => {
    if(record?.payment?.gatewayID) {
        return TRANSACTION.GATEWAY[record?.payment?.gatewayID];
    } else if (record?.payment?.cash) {
        return "Cash";
    } else if (record?.payment?.terminal) {
        return "Paywave";
    } else if (record?.payment?.ghlPaymentDetail?.paymentResponse?.msg?.productCode) {
        return record.payment.ghlPaymentDetail.paymentResponse.msg.productCode.toString();
    } else {
        return "Others";
    }
};