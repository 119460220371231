import { Button, Menu, MenuItem } from '@material-ui/core';
import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useDataProvider, useRecordContext, useRedirect } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import MoreIcon from "@material-ui/icons/ExpandMoreOutlined";

const useStyles = makeStyles((theme) => ({
    icon: {
      marginLeft: theme.spacing(0.5),
      width: 20,
    },
    menuItem: {
      padding: theme.spacing(1),
    },
    actionButton: {
      justifyContent: "flex-start",
      padding: theme.spacing(1, 2),
      width: "100%",
      textAlign: "left",
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
    },
}));

const CategoryGroupField = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const navigate = useNavigate();
    const redirect = useRedirect();
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const handleEdit = () => {
        const editUrl = `/categories/edit/${record.id}`;
        redirect(editUrl);
    };

    const handleDelete = async () => {
        try{
            await dataProvider.delete("categories", { id: record.id });
            navigate("/categories");
            setTimeout(() => navigate(0), 1000);
        } catch (error) {
            console.log("error: ", error);
        }
    };
    
    return (
        <>
            <Button
                aria-haspopup="true"
                onClick={handleClick}
                size="small"
                endIcon={<MoreIcon className={classes.icon} />}
            >
                More
            </Button>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={handleEdit} className={classes.menuItem}>
                    {record && (
                        <Button
                            label="Edit"
                            className={classes.actionButton}
                            color="primary"
                        >Edit</Button>
                    )}
                </MenuItem>
                <MenuItem className={classes.menuItem} onClick={handleDelete}>
                    {record && record.id && (
                        <Button
                            size="small"
                            className={`${classes.actionButton}`}
                            style={{ padding:"6px 16px", color: "red" }}
                        >
                            Delete
                        </Button>
                    )}
                </MenuItem>
            </Menu>
        </>
    );
}

export default CategoryGroupField;