import React from "react";
import logo from "../assets/img/ve-cloud.png";
import { FaGem, FaHeart, FaList, FaBars, FaHome, FaRegEnvelope, FaCalendarAlt, FaTrello, FaRegUser, FaRegFileAlt, FaRegImages, FaRegCheckSquare, FaBloggerB, FaFileInvoice, FaRegFileCode, FaChevronDown, FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu,  } from "react-pro-sidebar";

import './css/SideBar.css';

const SideBar = ({  handleMouseLeave, handleMouseEnter, toggled, setToggled, setBroken, collapsed, userAccess }) => {
    
    return(
        <>
            <div onMouseEnter={() => handleMouseEnter()} onMouseLeave={() => handleMouseLeave()} style={{ display: "flex", height:"100%" }}>
                <Sidebar 
                    collapsed={collapsed}
                    toggled={toggled}
                    onBackdropClick={() => setToggled(false)}
                    onBreakPoint={setBroken}
                    breakPoint="md"
                    className="sidebar-container" 
                    style={{ zIndex:"9999", backgroundColor:"#f8f9fa" }} 
                >
                    <div className="sidebar-header">
                        <img src={logo} alt="logo" className="ve-logo" />
                        {!collapsed && <span>VECLOUD</span>}
                    </div>
                    <Menu iconShape="circle">
                        <MenuItem icon={<FaGem />}>
                            <Link className="link-underline" to="/dashboard">Dashboard</Link>
                        </MenuItem>
                        {userAccess.includes("report") && (
                            <MenuItem icon={<FaRegFileCode />}>
                                <Link className="link-underline" to="/transactions">Sales Report</Link>
                            </MenuItem>
                        )}
                        {userAccess.includes("mro_report") && (
                            <SubMenu label="MRO Report" icon={<FaRegFileAlt />} openIcon={<FaChevronDown />} closeIcon={<FaChevronRight />}>
                                <Link className="link-underline" to="/mro-transactions">
                                    <MenuItem>By Transaction</MenuItem>
                                </Link>
                                <Link className="link-underline" to="/mro-transactions-by-product">
                                    <MenuItem>By Product</MenuItem>
                                </Link>
                                <Link className="link-underline" to="/mro-transactions-by-employee">
                                    <MenuItem>By Employee</MenuItem>
                                </Link>
                            </SubMenu>
                        )}
                        {userAccess.includes("inventory") && (
                            <SubMenu label="Inventory" icon={<FaBars />}>
                                <Link className="link-underline" to="/products">
                                    <MenuItem>Product</MenuItem>
                                </Link>
                                <Link className="link-underline" to="/categories">
                                    <MenuItem>Category</MenuItem>
                                </Link>
                            </SubMenu>
                        )}
                        {userAccess.includes("vending_machine") && (
                            <SubMenu label="Vending Machine" icon={<FaRegImages />}>
                                <Link className="link-underline" to="/machines">
                                    <MenuItem>Status</MenuItem>
                                </Link>
                            </SubMenu>
                        )}
                        {userAccess.includes("users") && (
                            <Link className="link-underline" to="/users">
                                <MenuItem icon={<FaRegUser />}>Users</MenuItem>
                            </Link>
                        )}
                        {userAccess.includes("mro") && (
                            <SubMenu label="MRO" icon={<FaCalendarAlt />}>
                                <Link className="link-underline" to="/employees">
                                    <MenuItem>Employee</MenuItem>
                                </Link>
                                <Link className="link-underline" to="/employeeTypes">
                                    <MenuItem>Type</MenuItem>
                                </Link>
                                <Link className="link-underline" to="/employeeTopup">
                                    <MenuItem>Topup</MenuItem>
                                </Link>
                            </SubMenu>
                        )}
                        <SubMenu label="Settings" icon={<FaList />}>
                            <Link className="link-underline" to="/profile">
                                <MenuItem>Profile</MenuItem>
                            </Link>
                        </SubMenu>
                        <Link className="link-underline" to="/notifications">
                            <MenuItem icon={<FaTrello />}>Notifications</MenuItem>
                        </Link>
                    </Menu>
                </Sidebar>
            </div>
        </>
    )
}

export default SideBar;