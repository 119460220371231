import React from "react";
import { Table } from "react-bootstrap";

import "../components/css/LatestTrans.css";

const ProductsPerTable = () => {
    const products = [
        {
            "item_name": "Nescafe",
            "frequency": "282",
            "totalSales": "RM 1269.00", 
        },
        {
            "item_name": "B.Coffee",
            "frequency": "265",
            "totalSales": "RM 927.50", 
        },
        {
            "item_name": "D.Water",
            "frequency": "491",
            "totalSales": "RM 736.50", 
        },
        {
            "item_name": "Kit Kat",
            "frequency": "223",
            "totalSales": "RM 713.60", 
        },
    ]
    return(
        <>
            <div className="proTableDiv">
                <h6 className="lblProductTable">Product Ranking</h6>

                <Table striped bordered hover responsive size="sm">
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th>Frequency</th>
                            <th>Sales (RM)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.map((row) => (
                            <tr key={row.item_name}>
                                <td className="fontHandle">{row.item_name}</td>
                                <td className="fontHandle">{row.frequency}</td>
                                <td className="fontHandle">{row.totalSales.replace("RM", "").trim()}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </>
    );
};

export default ProductsPerTable;