import React, { useEffect, useState } from 'react'
import useDefaultFilter from './useDefaultFilter';
import { DateInput, SelectInput, SimpleForm, useDataProvider } from 'react-admin';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebase';
import { Close as CloseIcon } from '@mui/icons-material';
import { Button, IconButton, Drawer } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { Timestamp } from 'firebase/firestore';
import { renderTransactionStatus, transactionStatus } from '../Employee/ValidateCSV';


const useStyles = makeStyles(theme => ({
    form: {
        alignSelf: "flex-end",
        paddingBottom: 8,
    },
    formContent: {
        padding: theme.spacing(3),
    },
    formContentButtons: {
        display: 'flex',
        marginTop: theme.spacing(2),
    },
    closeButton: {
        marginTop: 5,
        marginLeft: 5,
    },
    searchBox: {
        display: 'flex',
        alignItems: 'baseline',
    },
    searchInput: {
        padding: 15,
    },
    searchButton: {
        marginLeft: 5,
    },
    drawer: {
        width: 420,
        maxWidth: "100%",
        paddingLeft: "1rem",
        paddingRight: "1rem",
    },
}));

const formatDate = (date, startOfDay = true) => {
    if(!date) return null;
    const d = new Date(date);
    return startOfDay
        ? new Date(d.setHours(0, 0, 0, 0)).getTime()
        : new Date(d.setHours(23, 59, 59, 999)).getTime();
};

const TransactionFilter = ({ setFilteredData, allData, onModalOpenChange, modalOpen, setAppliedFilters }) => {
    const dataProvider = useDataProvider();
    
    const [role, setRole] = useState(null);
    const [macList, setMacList] = useState([]);
    const [filters, setFilterValues] = useState({
        machineId: "",
        dateStart: "",
        dateEnd: "",
        status: "2",
    });

    useEffect(() => {
        const checkAuth = onAuthStateChanged(auth, async (user) => {
            if(user) {
                try {
                    await getCurrentUser(user.uid);
                    await getMachineUUID();
                } catch (err) {
                    console.error("Error in authentication or fetching data:", err);
                }
            } else {
                setRole(null);
            }
        });

        return () => checkAuth();
    }, [dataProvider]);

    const getCurrentUser = async (userId) => {
        try {
            const res = await dataProvider.getOne("users", { id: userId });
            setRole(res.data.role);
        } catch (err) {
            console.log(err);
            setRole(null)
        }
    };

    const getMachineUUID = async () => {
        try {
            const res = await dataProvider.getList("machines", {
                filter: { isDeleted: false },
                sort: { field: "machineUUID", order: "ASC" },
                pagination: { page: 1, perPage: 1000 },
            });

            //filter undefined machine UUID
            const filterMachine = res.data.filter((machine) => {
                return machine.machineUUID && !isNaN(parseInt(machine.machineUUID.replace(/[^0-9]/g, ""), 10));
            });

            const sortedMachines = filterMachine.sort((a,b) => {
                const numA = a.machineUUID 
                    ? parseInt(a.machineUUID.replace(/[^0-9]/g, ""), 10)
                    : 0;
                const numB = b.machineUUID
                    ? parseInt(b.machineUUID.replace(/[^0-9]/g, ""), 10)
                    : 0;
                return numA - numB;
            });

            setMacList(sortedMachines);
        } catch (err) {
            console.log(err);
            setMacList([]);
        }
    };

    const parseFormDataToFilters = (data) => {
        let filters = role === "client" ? { status: 1 } : {};
        const { machineId, dateStart, dateEnd, status } = data;

        if(machineId) {
            filters["machine.id"] = machineId;
        }

        if(dateStart || dateEnd) {
            const date = [];
            if(dateStart) {
                date.push({
                    operator: ">=",
                    value: formatDate(dateStart, true),
                    isDate: true,
                });
            }
            if(dateEnd) {
                date.push({
                    operator: "<=",
                    value: formatDate(dateEnd, false),
                    isDate: true,
                });
            }
            filters.date = date;
        }

        if(status !== undefined && status !== null) {
            const statusInNumber = parseInt(status, 10);
            if(statusInNumber >= -3 && statusInNumber <= 2) {
                filters.status = statusInNumber;
            }
        }

        return filters;
    };

    const parseFiltersToFormData = (filters) => {
        const formData = {};
        
        if(filters?.["machine.id"]) {
            formData.machineId = filters["machine.id"];
        }

        if(filters?.date) {
            filters.date.forEach(({ operator, value }) => {
                if(operator === ">=") {
                    formData.dateStart = new Date(value).toISOString().split("T")[0];
                } else if (operator === "<=") {
                    formData.dateEnd = new Date(value).toISOString().split("T")[0];
                }
            });
        }

        if(filters?.status !== undefined) {
            formData.status = filters.status.toString();
        }

        return formData;
    };

    //machine input filter
    const machineInputFilter = {
        ...useDefaultFilter("client.id"),
        isDeleted: false,
    };

    const handleChange = (e) => {
        if(!e?.target?.value) return;
        const { name, value } = e.target;
        setFilterValues((prev) => ({ ...prev, [name]: value }));
    };

    const applyFilters = () => {
        let filtered = [...allData];
        const appliedFilters = [];
        
        if (filters.machineId) {
            filtered = filtered.filter((item) => item.machine?.id === filters.machineId);
            const machineUUID = filtered[0]?.machine?.machineUUID.trim();
            appliedFilters.push({ key: "Machine UUID", value: machineUUID });
        }

        const { dateStart, dateEnd } = filters;
        if (dateStart || dateEnd) {
            filtered = filtered.filter((item) => {
                let itemDate;
                // Case 1: Firestore Timestamp
                if (item.date instanceof Timestamp) {
                    itemDate = item.date.toDate();
                } else if (item.date) {
                    itemDate = new Date(item.date);
                } else {
                    return true;
                }

                const startDate = dateStart ? new Date(dateStart) : null;
                const endDate = dateEnd ? new Date(dateEnd) : null;
                return (
                    (!startDate || itemDate >= startDate) &&
                    (!endDate || itemDate <= endDate)
                );
            });

            if(dateStart || dateEnd) {
                appliedFilters.push({ key: "Date", value: `${dateStart} - ${dateEnd}`.trim() });
            }
        };

        if (filters.status && filters.status !== "2") {
            filtered = filtered.filter((item) => item.status === parseInt(filters.status, 10));
            const statusValue = parseInt(filters.status, 10);
            const a = transactionStatus({ status: statusValue });
            appliedFilters.push({ key: "Status", value: a });
        }
        
        setAppliedFilters(appliedFilters)
        setFilteredData(filtered);
        handleClose();
    };

    const clearFilters = () => {
        setFilterValues({ machineId: '', dateStart: '', dateEnd: '', status: '2' });
        setFilteredData(allData);
        setAppliedFilters([]);
        // handleClose();
    };

    const handleClose = () => {
        onModalOpenChange?.(false);
    };
    
    return (
        <>
            <Drawer anchor="right" open={modalOpen} onClose={handleClose} 
                PaperProps={{
                    style: {
                        transform: "none", // Ensure the drawer is not hidden by transform
                        display: "block",  // Force visibility
                        width: "420px",    // Ensure proper width
                        maxWidth: "100%", // Ensure responsiveness
                    },
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <SimpleForm toolbar={null}>
                    <SelectInput
                        label="Machine UUID"
                        source="machineId"
                        choices={macList.map((mac) => ({
                            id: mac.id,
                            name: mac.machineUUID,
                        }))}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        emptyText="Show All Machines"
                        allowEmpty={false}
                    />
                    <DateInput
                        label="Start Date"
                        type="date"
                        name="dateStart"
                        value={filters.dateStart}
                        onChange={handleChange}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        margin="normal"
                    />
                    <DateInput
                        label="End Date"
                        type="date"
                        name="dateEnd"
                        value={filters.dateEnd}
                        onChange={handleChange}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        margin="normal"
                    />
                    <SelectInput
                        label="Status"
                        source="status"
                        choices={[
                            { id: "1", name: "Success" },
                            { id: "0", name: "Failed" },
                            { id: "-1", name: "Canceled" },
                            { id: "-2", name: "Refunded" },
                            { id: "-3", name: "Void" },
                            { id: "2", name: "ALL" },
                        ]}
                        onChange={handleChange}
                        defaultValue="2" // Set default value to "ALL"
                        fullWidth
                        margin="normal"
                        allowEmpty={false}
                        emptyText={null}
                    />
                </SimpleForm>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20, marginBottom: 20, paddingRight: 16, paddingLeft: 16 }}>
                    <Button variant="contained" color="primary" onClick={applyFilters}>
                        Apply Filters
                    </Button>
                    <Button variant="outlined" onClick={clearFilters}>
                        Clear
                    </Button>
                </div>
            </Drawer>
        </>
    );
}

export default TransactionFilter;
