import React, { useState } from 'react'
import { InputAdornment, TextField, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useListContext } from 'react-admin';

const SearchInput = ({ searchKeys = [], setFilteredData }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const { data } = useListContext();

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSearch = () => {
        const dataArray = Array.isArray(data) ? data : [];
        
        if(searchTerm.trim() !== "") {
            const filteredData = dataArray.filter(item =>
                searchKeys.some(key => {
                    const value = key.split('.').reduce((acc, part) => acc && acc[part], item);
                    return value && value.toString().toLowerCase().includes(searchTerm.toLowerCase());
                })
            );
            setFilteredData(filteredData);
        } else {
            setFilteredData([]);
        }
    };

    const handleKeyDown = (e) => {
        if(e.key === "Enter") {
            handleSearch();
        }
    };

    return (
        <>
            <TextField
                value={searchTerm}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder="Search..."
                variant="outlined"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleSearch}>
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </>
    )
}

export default SearchInput;
