import React from "react";
import Table from "react-bootstrap/Table";
import { RiPieChart2Line } from "react-icons/ri";

import "../components/css/MachineHealth.css";

const MachineHealth = () => {
    const machineList = [
        {
            "machine_id":"VE000234",
            "machine_condition":"green",
            "stock":"40",
            "stock_condition":"yellow",
            "health": "85",
            "health_condition":"green",
            "lastTrans":"Milo 5sec ago"
        },
        {
            "machine_id":"VE000172",
            "machine_condition":"green",
            "stock":"50",
            "stock_condition":"green",
            "health": "95",
            "health_condition":"green",
            "lastTrans":"Drinking Water 1hour ago"
        },
        {
            "machine_id":"VE000272",
            "machine_condition":"green",
            "stock":"25",
            "stock_condition":"red",
            "health": "60",
            "health_condition":"yellow",
            "lastTrans":"Mineral Water 2hours ago"
        },
        {
            "machine_id":"VE000225",
            "machine_condition":"red",
            "stock":"5",
            "stock_condition":"red",
            "health": "30",
            "health_condition":"red",
            "lastTrans":"Black Coffee 24hours ago"
        },
    ];

    const calcTimeAgo = (date) => {
        const now = new Date();
        const seconds = Math.floor((now - date) / 1000);

        let interval = Math.floor(seconds / 31536000);
        if(interval > 1) {
            return `${interval} years ago`;
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
            return `${interval} months ago`;
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
            return `${interval} days ago`;
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
            return `${interval} hours ago`;
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
            return `${interval} minutes ago`;
        }
        return `${Math.floor(seconds)} seconds ago`;
    };

    return(
        <>
            <h6 className="lblMachineHealth">List of Machines</h6>
            <Table striped bordered hover responsive size="sm">
                <thead>
                    <tr>
                        <th>Machines</th>
                        <th>Stock (%)</th>
                        <th>Health (%)</th>
                        <th>Last Transactions</th>
                    </tr>
                </thead>
                <tbody>
                    {machineList.map((i) => (
                        <tr key={i.machine_id}>
                            <td className="fontHandle">
                                <div className="healthMachineIcon">
                                    <div>{i.machine_id}</div>
                                    <div className={i.machine_condition === "green" ? "greenDot" : "redDot"}></div>
                                </div>
                            </td>
                            <td className="fontHandle">
                                <div className="healthMachineIcon">
                                    <div>{i.stock}</div>
                                    <div>
                                        <RiPieChart2Line className={i.stock_condition === "green" ? "green-icon" : i.stock_condition === "yellow" ? "yellow-icon" : "red-icon"} />
                                    </div>
                                </div>
                            </td>
                            <td className="fontHandle">
                                <div className="healthMachineIcon">
                                    <div>{i.health}</div>
                                    <div>
                                        <RiPieChart2Line className={i.health_condition === "green" ? "green-icon" : i.health_condition === "yellow" ? "yellow-icon" : "red-icon"} />
                                    </div>
                                </div>
                            </td>
                            <td className="fontHandle">{i.lastTrans.toLowerCase().replace("mineral ", "m.").replace("drinking ", "d.").replace("black ", "b.")}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    );
};

export default MachineHealth;