import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import { useDataProvider } from 'react-admin';
import { auth } from '../../firebase';

const useDefaultFilter = (clientField = "client.id") => {

    const dataProvider = useDataProvider();
    const [filter, setFilter] = useState({});

    useEffect(() => {
        const checkAuth = onAuthStateChanged(auth, async (user) => {
            if(user) {
                const userFilter = await getCurrentUser(user.uid);
                setFilter(userFilter);
            } else {
                setFilter({});
            }
        })

        return () => checkAuth();
    }, [dataProvider]);

    const getCurrentUser = async (userId) => {
        try {
            const res = await dataProvider.getOne("users", { id: userId });
            
            return res.data.role === "client"
                ? { [clientField]: res.data.client.id }
                : {}; 
        } catch (err) {
            console.log(err);
            return {};
        }
    };

    return filter;
};

export default useDefaultFilter;
