import { onAuthStateChanged } from 'firebase/auth';
import React, { useCallback, useEffect, useState } from 'react'
import { Datagrid, ExportButton, FunctionField, List, NumberField, ReferenceField, TextField, TopToolbar, useDataProvider, useListContext } from 'react-admin';
import { auth, db } from '../../../firebase';
import { doc, getDoc, query } from 'firebase/firestore';
import { Button, CardHeader, Chip, Modal } from '@material-ui/core';
import { getPrice, getProductName, getRefId, getTotalCashReceived, getTotalCashReturned, getTransId, renderDate, renderGatewayField, renderProductSlot, renderTime, renderTransactionStatus, statusForExcelReport } from '../../Employee/ValidateCSV';
import CustomizedDialogs from './TransactionDetails';
import { makeStyles } from "@material-ui/core/styles";
import TransactionFilter from '../../Filters/TransactionFilter';
import { FilterList as FilterListIcon } from '@mui/icons-material';
import SearchInput from '../../SearchInput';
import { Pagination } from "react-bootstrap";
import _extendLayout from '../../../layout/_extendLayout';

const CustomPagination = ({ total, currentPage, perPage, onPageChange }) => {
    const totalPages = Math.ceil(total / perPage);

    const handlePageChange = (page) => {
        if(page >= 1 && page <= totalPages) {
            onPageChange(page);
        }
    };

    return (
        <Pagination>
            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
            {[...Array(totalPages)].map((_, index) => (
                <Pagination.Item
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => handlePageChange(index + 1)}
                >
                    {index + 1}
                </Pagination.Item>
            ))}
            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
        </Pagination>
    )
    
};

const SalesTransactionList = (props) => {
    const dataProvider = useDataProvider();
    const classes = useStyles();

    const [isAdmin, setIsAdmin] = useState(false);
    const [clientId, setClientId] = useState("");
    const [status, setStatus] = useState(null);
    const [salesList, setSalesList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [searchQuery, setSearchQuery] = useState("");
    const [currentFilter, setCurrentFilter] = useState({});
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage] = useState(1000);
    const [appliedFilters, setAppliedFilters] = useState([]);

    useEffect(() => {
        const checkAuth = onAuthStateChanged(auth, async(user) => {
            if(user) {
                let filter = await getCurrentUser(user.uid);
                setCurrentFilter(filter);
                await getTransactionList(filter, { page: currentPage, perPage: perPage }, { field: "date", order: "DESC" });
            }
        });
        return () => {};
    }, []);

    const getCurrentUser = async (userId) => {
        try {
            const res = await dataProvider.getOne("users", { id: userId });
            const isAdminUser = res.data.role === "admin";
            setIsAdmin(isAdminUser);
            
            if(!isAdminUser) setClientId(res.data.client?.id);

            let currentFilter = isAdminUser
                ? { mode: "commercial" }
                : {
                    client: res.data?.client?.id,
                    mode: "commercial",
                    status: 1,
                };

            if(!isAdminUser) {
                await getClientData(res.data.client?.id);
            }

            return isAdminUser
                ? { mode: "commercial" }
                : { client: res.data?.client?.id, mode: "commercial", status: 1 };
        } catch (err) {
            console.log(err);
        }
    };

    const getClientData = async (client) => {
        try {
            if(!isAdmin) {
                const clientRef = doc(db, "clients", client);
                const clientSnapshot = await getDoc(clientRef);
                
                if(clientSnapshot.exists()) {
                    const showFailTransaction = clientSnapshot.data().showFailTransaction;
                    setStatus(showFailTransaction);
                } else {
                    console.log("Client document does not exist.");
                }
            } else {
                setStatus(true);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const getTransactionList = async (filter, pagination, sort) => {
        try {
            const res = await dataProvider.getList("transactions", { 
                filter: { "client": filter.client, mode: "commercial", status: 1 },
                sort: { field: "date", order: "DESC" },
                pagination: { page: 1, perPage: 1000 },
            });
            
            const newFilteredData = res.data.filter((item) => item.client === filter.client && item.mode === "commercial" && item.status === 1);

            if(Array.isArray(newFilteredData)) {
                setTotal(res.total)
                setFilteredData(newFilteredData);
                setSalesList(newFilteredData);
            } else {
                console.error("getTransactionList returned invalid data:", newFilteredData);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const renderAdminMoreButtonField = record => {
        if(!record) return null;
        return (
            <Button
                size="medium"
                onClick={() => {
                    setDetailsOpen(true);
                    setModalContent(JSON.stringify(record, null, 2))
                }}
            >
                &gt;
            </Button>
        );
    };

    const renderCustomerMoreButtonField = record => {
        if(!record) return null;
        return (
            <Button
                size="medium"
                onClick={() => {
                    setDetailsOpen(true);
                    // const dialogContent = <CustomizedDialogs record={record} className={classes} />;
                    setModalContent(
                        <CustomizedDialogs
                            record={record}
                            open={true}
                            onClose={() => setDetailsOpen(false)}
                        />
                    );
                }}
            >
                &gt;
            </Button>
        );
    };

    const removeFilter = (key) => {
        setAppliedFilters((prevFilters) =>
            prevFilters.filter((filter) => filter.key !== key)
        );
    };

    const CustomActions = React.memo(({ searchInput, ...props }) => (
        <TopToolbar style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", flex: 1 }}>
                {searchInput}
            </div>
            {/* Render Filter Tags */}
            {appliedFilters.map((filter, index) => (
                <Chip
                    key={index}
                    label={`${filter.key} = ${filter.value}`}
                    // onDelete={() => removeFilter(filter.key)}
                    color="primary"
                />
            ))}
            <Button
                startIcon={<FilterListIcon />}
                onClick={() => props.onShowFilters()}
                variant="outlined"
                color="primary"
                style={{ border: "none" }}
            >
                Show Filters
            </Button>
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <TransactionFilter
                    setFilteredData={props.setFilteredData}
                    allData={props.data}
                    onModalOpenChange={handleModalOpenChange}
                    modalOpen={modalOpen}
                    setAppliedFilters={setAppliedFilters}
                />
                <ExportButton label="Export" {...props} exporter={() => props.salesExporter(salesList)} />
            </div>
        </TopToolbar>
    ));

    const handleModalOpenChange = useCallback((isOpen) => {
        setModalOpen(isOpen);
    }, []);

    const hanldeFilteredData = (newFilteredData) => {
        setFilteredData(newFilteredData)
    };

    const salesExporter = async (datas) => {
        
        try {

            const headers = [
                "ID",
                "Machine UUID",
                "Date",
                "Time",
                "Product",
                "Slot",
                "Price (RM)",
                "Gateway",
                "Status",
                "Total Cash Received",
                "Total Cash Returned",
                "Balance",
                "Transaction ID",
                "Reference No",
            ];

            let csvRows = [headers.join(",")];

            const filterData = datas.filter((e) => e.client === clientId);
            
            filterData.forEach(e => {

                const date = renderDate(e);
                const time = renderTime(e);
                const slot = renderProductSlot(e);
                const gateway = renderGatewayField(e);
                const status = statusForExcelReport(e.status);
                const totalCashReceived = getTotalCashReceived(e);
                const totalCashReturned = getTotalCashReturned(e);
                const transId = getTransId(e);
                const refId = getRefId(e);
                const balance = totalCashReceived && totalCashReturned ? totalCashReceived - totalCashReturned : "";
                const productName = getProductName(e);
                const price = getPrice(e);

                const commonDetails = [
                    e.id,
                    e.machine.machineUUID ?? "",
                    date ?? "",
                    time ?? "",
                    productName ?? "",
                    slot ?? "",
                    price ?? "",
                    gateway ?? "",
                    status ?? "",
                    totalCashReceived ?? "",
                    totalCashReturned ?? "",
                    balance ?? "",
                    transId ?? "",
                    refId ?? "",
                ];
                csvRows.push([...commonDetails].join(","));
            });

            const csvContent = csvRows.join("\n");
            const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = "Transactions.csv";
            link.style.display = "none";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch(err) {
            console.log(err);
        }
    };

    const handleSearchInput = (query) => {
        setSearchQuery(query);
        if(query) {
            const filtered = salesList.filter((item) =>
                ["machine.machineUUID"].some((key) => {
                    const value = key.split(".").reduce((acc, part) => acc && acc[part], item);
                    return value && value.toString().toLowerCase().includes(query.toLowerCase());
                })
            );
            setFilteredData(filtered);
        } else {
            setFilteredData(salesList);
        }
    };

    return (
        <>
            <_extendLayout isModalOpen={modalOpen || detailsOpen}>
                <CardHeader title="Sales Transaction List" />
                <div style={{ width: '90%', margin: '0 auto', padding: '20px'}}>
                    <List
                        {...props}
                        resource="transactions"
                        filter={currentFilter}
                        sort={{ field: "date", order: "DESC" }}
                        // filters={<SearchInput searchKeys={["machine.machineUUID"]} onFilteredData={handleSearchInput} />}
                        perPage={25}
                        actions={
                            <CustomActions 
                                // searchInput={<SearchInput searchKeys={["machine.machineUUID"]} data={salesList} onFilteredData={handleSearchInput} />}
                                onShowFilters={() => setModalOpen(true)} // Open the Drawer
                                handleModalOpenChange={handleModalOpenChange} // Pass callback for modal state
                                isModalOpen={modalOpen}
                                setFilteredData={setFilteredData}
                                data={salesList}
                                salesExporter={salesExporter}
                            />
                        }
                    >
                        <Datagrid data={filteredData.length > 0 ? filteredData.slice((currentPage - 1) * perPage, currentPage * perPage) : undefined} bulkActionButtons={false} sx={{ "& .RaDatagrid-headerCell": { fontWeight: "bold" } }}>
                            <TextField label="Machine UUID" source="machine.machineUUID" />
                            <FunctionField label="Date" render={(record) => renderDate(record)} />
                            <FunctionField label="Time" render={(record) => renderTime(record)} />
                            {isAdmin && (
                                <ReferenceField label="Company" source="client" reference="clients" link={false}>
                                    <TextField source="companyName" />
                                </ReferenceField>
                            )}
                            <TextField label="Product Name" source="product.name" />
                            <FunctionField label="Product Slot" render={renderProductSlot} />
                            <NumberField
                                source="amount"
                                label="Product Price (RM)"
                                locales="en-MY"
                                textAlign="left"
                                options={{ style: "currency", currency: "MYR" }} 
                            />
                            <FunctionField label="Gateway" render={(record) => renderGatewayField(record)} />
                            <FunctionField label="Status" render={(record) => renderTransactionStatus(record)} />
                            {isAdmin ? (
                                <FunctionField label="" render={(record) => renderAdminMoreButtonField(record)} />
                            ) : (
                                <FunctionField label="" render={(record) => renderCustomerMoreButtonField(record)} />
                            )}
                        </Datagrid>
                    </List>
                    {/* {salesList.length <= 0 ? (
                        <p>Loading...</p>
                    ) : (
                        <List {...listProps} 
                            actions={
                                <CustomActions
                                    searchInput={<SearchInput searchKeys={["machine.machineUUID"]} data={salesList} onFilteredData={hanldeFilteredData} />}
                                    onShowFilters={() => setModalOpen(true)} // Open the Drawer
                                    handleModalOpenChange={handleModalOpenChange} // Pass callback for modal state
                                    isModalOpen={modalOpen}
                                    setFilteredData={setFilteredData}
                                    data={salesList}
                                    salesExporter={salesExporter}
                                />
                            }
                        >
                            <Datagrid data={filteredData} bulkActionButtons={false} sx={{ '& .RaDatagrid-headerCell': { fontWeight: 'bold' } }}>
                                <TextField label="Machine UUID" source="machine.machineUUID" />
                                <FunctionField label="Date" render={(record) => renderDate(record)} />
                                <FunctionField label="Time" render={(record) => renderTime(record)} />
                                {isAdmin && (
                                    <ReferenceField label="Company" source="client" reference="clients" link={false}>
                                        <TextField source="companyName" />
                                    </ReferenceField>
                                )}
                                <TextField label="Product Name" source="product.name" />
                                <FunctionField label="Product Slot" render={(record) => renderProductSlot(record)} />
                                <NumberField
                                    label="Product Price (RM)"
                                    source="amount"
                                    locales="en-MY"
                                    options={{ style: "currency", currency: "MYR" }}
                                    textAlign="left"
                                />
                                <FunctionField label="Gateway" render={(record) => renderGatewayField(record)} />
                                <FunctionField label="Status" render={(record) => renderTransactionStatus(record)} />
                                {isAdmin ? (
                                    <FunctionField label="" render={(record) => renderAdminMoreButtonField(record)} />
                                ) : (
                                    <FunctionField label="" render={(record) => renderCustomerMoreButtonField(record)} />
                                )}
                            </Datagrid>
                        </List>
                    )} */}
                </div>
                <Modal open={detailsOpen} onClose={() => setDetailsOpen(false)}>
                    <div className={classes.paper}>
                        {typeof modalContent === "string" ? (
                            <pre>{modalContent}</pre>
                        ) : (
                            modalContent
                        )}
                    </div>
                </Modal>
            </_extendLayout>
        </>
    );
};

const useStyles = makeStyles(theme => ({
    paper: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: 500,
      maxHeight: '90%',
      overflow: 'scroll',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
}));

export default SalesTransactionList;
