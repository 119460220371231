import { Button, CardHeader, Chip } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react'
import { Datagrid, DateField, ExportButton, FunctionField, List, NumberField, ReferenceField, TextField, TopToolbar, useDataProvider } from 'react-admin';
import TransactionFilter from '../Filters/TransactionFilter';
import SearchInput from '../SearchInput';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebase';
import { employeeType, getPriceInTransaction, productDetails, productType, renderTransactionStatus } from "../Employee/ValidateCSV";
import _extendLayout from '../../layout/_extendLayout';
import { FilterList as FilterListIcon } from '@mui/icons-material';

const TransactionList = (props) => {

    const dataProvider = useDataProvider();

    const [isAdmin, setIsAdmin] = useState(false);
    const [clientId, setClientId] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [transData, setTransData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [appliedFilters, setAppliedFilters] = useState([]);
    
    useEffect(() => {
        const checkAuth = onAuthStateChanged(auth, async (user) => {
            if(user) {
                await getCurrentUser(user.uid);
            }
        });

        return () => checkAuth();
    }, [dataProvider]);

    const getCurrentUser = async (userId) => {
        try {
            const res = await dataProvider.getOne("users", { id: userId });
            setClientId(res.data?.client?.id);
            setIsAdmin(res.data.role === "admin");
            await getTransactionList(res.data?.client?.id);
        } catch(err) {
            console.log(err);
        }
    };

    const getTransactionList = async (client) => {
        try {
            const res = await dataProvider.getList("transactions", {
                filter: { client: client, mode: "mro" },
                sort: { field: "date", order: "DESC" },
                pagination: { page: 1, perPage: 1000 },
            });

            const enrichedData = await Promise.all(
                res.data.map(async (record) => {
                    try {
                        const priceRM = await getPriceInTransaction(record);
                        const date = record.date.toDate();
                        return { ...record, priceRM, date };
                    } catch (err) {
                        console.log("Error enriching transaction:", record, err);
                        return record;
                    }
                })
            );

            const sortedData = enrichedData.sort((a, b) => b.date - a.date);

            setTransData(sortedData);
            setFilteredData(sortedData);
        } catch (err) {
            console.log(err)
        }
    };

    const tranExporter = async (mros) => {
        
        const headers = [
            "Date / Time",
            "Machine UUID",
            "Employee Type",
            "Employee Name",
            "Employee Card Number",
            "Product Category",
            "Product Name (Transaction)",
            "Product Name (Current)",
            "Product SKU",
            "Product Serial",
            "Slot",
            "Quantity",
            "Credit",
            "Employee Cost Center",
            "Employee Number",
            "Employee Params Tag",
            "Employee Params Value",
            "Product Price",
            "Response Status Data",
            "Response Status Extra Spin Enabled",
            "Response Status Global Dropped Sensor",
            "Response Status Item Dropped",
            "Response Status Item Dropped Sensor",
            "Response Status Locker Opened",
            "Response Status Motor Turned",
            "Response Status No Of Extra Spin",
            "Response Status No Of Item Dropped",
            "Employee Params",
            "Response Status YY Item Dropped",
            "Response Status YY Motor Response No Timed Out",
            "Response Status YY Motor Response Reset After Rotation",
            "Response Status YY Motor Response Reset Before Rotation",
            "Response Status YY Motor Response with Feedback Signal",
        ];

        let csvRows = [headers.join(",")];
        
        const filteredMros = mros.filter((m) => m.client === clientId && m.client !== null);

        let datas = await Promise.all(
            filteredMros.map(async (p) => {
                //get employee type
                let s = await employeeType(p);
        
                //get product type
                let pt = await productType(p);
        
                //get product details
                let pd = await productDetails(p);


                return { ...p, employeeType: s, productType: pt, productDetails: pd };
            })
        );

        datas.forEach(m => {
            const formattedDate = m.date?.toDate
            ? `"${m.date.toDate().toLocaleString()}"`
            : m.date?.seconds
                ? `"${new Date(m.date.seconds * 1000).toLocaleString()}"`
                : '""';

            const commonDetails = [
                formattedDate,
                m.machine?.machineUUID ?? "",
                m.employeeTypeName ?? "",
                m.employee?.fullName ?? "",
                m.employee?.cardNumber ?? "",
                m.product?.categoryId ?? "",
                m.product?.name ?? "",
                m.productName ?? "",
                m.productDetails?.productSku ?? "",
                m.productDetails?.productSerial ?? "",
                m.product?.slot ?? "", 
                m.quantity ?? "",
                m.product?.credit ?? "",
                m.employee?.costCenter ?? "",
                m.employee?.employeeNumber ?? "",
                (m.employee?.params || []).map((p) => p.tag).join(", ") ?? "",
                (m.employee?.params || []).map((p) => p.value).join(", ") ?? "",
                m.product?.price ?? "",
                m.responseStatus?.data ?? "",
                m.responseStatus?.extraSpinEnabled ?? "",
                m.responseStatus?.globalDroppedSensor ?? "",
                m.responseStatus?.itemDropped ?? "",
                m.responseStatus?.itemDroppedSensor ?? "",
                m.responseStatus?.lockerOpened ?? "",
                m.responseStatus?.motorTurned ?? "",
                m.responseStatus?.noOfExtraSpin ?? "",
                m.responseStatus?.noOfItemDropped ?? "",
                (m.employee?.params || []).map(param => `${param.tag}: ${param.value}`).join(", ") ?? "",
                m.responseStatus?.yyItemDropped ?? "",
                m.responseStatus?.yyMotorResponse?.noTimedOut ?? "",
                m.responseStatus?.yyMotorResponse?.resetAfterRotation ?? "",
                m.responseStatus?.yyMotorResponse?.resetBeforeRotation ?? "",
                m.responseStatus?.yyMotorResponse?.witFeedbackSignal ?? "",

                // `"${m.mroAccessGroup?.join(", ") ?? ""}"`,
            ];
            csvRows.push([...commonDetails].join(","));
        });

        const csvContent = csvRows.join("\n");
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "MRO Transactions.csv";
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    
    const handleModalOpenChange = useCallback((isOpen) => {
        setIsModalOpen(isOpen);
    }, []);

    const CustomActions = React.memo(({ searchInput, ...props }) => (
        <TopToolbar style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", flex: 1 }}>
                {searchInput}
            </div>
            {/* Render Filter Tags */}
            {appliedFilters.map((filter, index) => (
                <Chip
                    key={index}
                    label={`${filter.key} = ${filter.value}`}
                    // onDelete={() => removeFilter(filter.key)}
                    color="primary"
                />
            ))}
            <Button
                startIcon={<FilterListIcon />}
                onClick={() => props.onShowFilters()}
                variant="outlined"
                color="primary"
                style={{ border: "none" }}
            >
                Show Filters
            </Button>
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <TransactionFilter
                    setFilteredData={props.setFilteredData}
                    allData={props.data}
                    onModalOpenChange={handleModalOpenChange}
                    modalOpen={isModalOpen}
                    setAppliedFilters={setAppliedFilters}
                />
                <ExportButton label="Export" {...props} exporter={props.tranExporter} />
            </div>
        </TopToolbar>
    ));

    const hanldeFilteredData = (newFilteredData) => {
        setFilteredData(newFilteredData)
    };

    const searchFilters = <SearchInput searchKeys={["machine.machineUUID"]} setFilteredData={hanldeFilteredData} />;
    console.log(filteredData)
    return (
        <>
            <_extendLayout isModalOpen={isModalOpen}>
                <CardHeader title="MRO Transaction List" />
                <div style={{ width: '90%', margin: '0 auto', padding: '20px' }}>
                    {/* <List
                        {...props}
                        resource="transactions"
                        basepath="/transactions"
                        filter={isAdmin ? {} : { "client": clientId }}
                        sort={{ field: 'date', order: 'DESC' }}
                        perPage={25}
                        data={transData}
                        hasCreate={false}
                        actions={
                            <CustomActions
                                searchInput={<SearchInput searchKeys={["machine.machineUUID"]} data={transData} onFilteredData={hanldeFilteredData} />}
                                onShowFilters={() => setIsModalOpen(true)} // Open the Drawer
                                handleModalOpenChange={handleModalOpenChange} // Pass callback for modal state
                                isModalOpen={isModalOpen}
                                setFilteredData={setFilteredData}
                                data={transData}
                                tranExporter={tranExporter} // Pass exporter
                            />
                        }
                    >
                        <Datagrid data={filteredData} {...props} bulkActionButtons={false} sx={{ '& .RaDatagrid-headerCell': { fontWeight: 'bold' } }}>
                            <DateField label="Date/Time" source="date" showTime />
                            {isAdmin && (
                                <ReferenceField label="Company" source="client" reference="clients" link={false}>
                                    <TextField source="companyName" />
                                </ReferenceField>
                            )}
                            <TextField label="Machine UUID" source="machine.machineUUID" />
                            <TextField label="Employee Name" source="employee.fullName" emptyText="N/A" />
                            <ReferenceField label="Department" source="employee.id" reference="employees" link={false}>
                                <TextField source="type.name"></TextField>
                            </ReferenceField>
                            <TextField label="Product Name" source="product.name" />
                            <TextField label="QTY" source="quantity" />
                            <NumberField source="amount" label="Price(Cr)" />
                            <FunctionField label="Price (RM)" render={(record) => record.priceRM || "-"} />
                            <FunctionField label="Status" render={renderTransactionStatus} />
                        </Datagrid>
                    </List> */}
                    <List
                        {...props}
                        resource="transactions"
                        filter={{ "client": clientId, mode: "mro" }}
                        sort={{ field: 'date', order: 'DESC' }}
                        perPage={25}
                        hasCreate={false}
                        filters={searchFilters}
                        actions={
                            <CustomActions
                                onShowFilters={() => setIsModalOpen(true)} // Open the Drawer
                                handleModalOpenChange={handleModalOpenChange} // Pass callback for modal state
                                isModalOpen={isModalOpen}
                                setFilteredData={setFilteredData}
                                data={transData}
                                tranExporter={tranExporter}
                            />
                        }
                    >
                        <Datagrid data={filteredData.length > 0 ? filteredData : undefined} bulkActionButtons={false} sx={{ '& .RaDatagrid-headerCell': { fontWeight: 'bold' } }}>
                            <DateField source="date" label="Date" options={{ day: "2-digit", month: "2-digit", year: "numeric" }} locales="en-GB" />
                            {isAdmin && (
                                <ReferenceField label="Company" source="client" reference="clients" link={false}>
                                    <TextField source="companyName" />
                                </ReferenceField>
                            )}
                            <TextField label="Machine UUID" source="machine.machineUUID" />
                            <TextField label="Employee Name" source="employee.fullName" emptyText="" />
                            <ReferenceField label="Department" source="employee.id" reference="employees" link={false}>
                                <TextField source="type.name"></TextField>
                            </ReferenceField>
                            <TextField label="Product Name" source="product.name" />
                            <TextField label="QTY" source="quantity" />
                            <NumberField source="amount" label="Price(Cr)" />
                            <FunctionField label="Price (RM)" render={(record) => record.priceRM || "-"} />
                            <FunctionField label="Status" render={renderTransactionStatus} />
                        </Datagrid>
                    </List>
                </div>
            </_extendLayout>
        </>
    );
};

export default TransactionList;
