import React, { useCallback, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import { isEmpty, useDataProvider } from 'react-admin';
import { checkField, randomIdMaker, convert2string, csv2json } from './ValidateCSV';
import { doc, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../../firebase';
import ErrorDialog from './ErrorDialog';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        maxWidth: '500',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const styleDropzone = {
    height: "300px",
    width: "500px",
    "background-color": "#e0e0e0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return(
        <MuiDialogTitle disabledTypography {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={() => onClose()}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    )
})

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const EmployeeImport = ({ onModalOpenChange, modalOpen }) => {
    const dataProvider = useDataProvider();

    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [clientId, setClientId] = useState("");

    useEffect(() => {
        const checkAuth = onAuthStateChanged(auth, async (user) => {
            if(user) {
                await getCurrentUser(user.uid);
            }
        });

        return () => checkAuth();
    }, [auth]);

    const getCurrentUser = async (userId) => {
        try {
            const res = await dataProvider.getOne("users", { id: userId });
            setClientId(res.data.client.id);
        } catch (err) {
            console.log("error is ", err);
        }
    };

    const handleClose = () => {
        onModalOpenChange?.(false);
    };

    // const handleClickOpen = useCallback(() => {
    //     console.log("Button clicked"); // Debug log
    //     setIsOpen(true);
    //     onModalOpenChange?.(true);
    // }, [onModalOpenChange]);

    const handleUploadEvent = () => {
        if(acceptedFiles.length > 1) {
            alert("Only one document can be uploaded at a time");
            return;
        } else if(acceptedFiles.length === 0) {
            alert("Please upload your employee CSV file first");
            return;
        } else {
            let tempField = [];
            let tempResult = [];

            const array = acceptedFiles[0].name.split(".");
            if(array[array.length - 1] !== "csv") {
                alert("Sorry!, We only accept CSV format");
                return;
            }
            Papa.parse(acceptedFiles[0], {
                complete: async (results) => {
                    for(let i = 0; i < results.data.length; i++) {
                        if(i === 0) {
                            tempField.push(results.data[i]);
                        } else {
                            tempResult.push(results.data[i]);
                        }
                    }

                    let consoleError = [];
                    const tempErrorList = checkField(tempField, consoleError);

                    for(let i = 0; i < tempResult.length; i++) {
                        for(let j = i + 1; j < tempResult.length; j++) {
                            if(tempResult[i][2] === tempResult[j][2]) {
                                tempErrorList.push(`Row ${i + 2} and Row ${j + 2}: Card number duplicated`);
                            }
                        }
                    }
                    
                    const [jsonObjectId, jsonObjectArray, errorList] = await csv2json(clientId, tempResult, tempErrorList);
                    
                    if(isEmpty(errorList)) {
                        const updatePromises = Object.keys(jsonObjectId).map(async (id, index) => {
                            const employeeId = jsonObjectId[id];
                            const employee = jsonObjectArray[index];

                            if(employeeId) {
                                try {
                                    const employeeRef = doc(db, "employees", employeeId);
                                    await updateDoc(employeeRef, employee);
                                    alert("Updated successfully. Please refresh");
                                } catch (err) {
                                    console.log("Error updating:", err);
                                }
                            } else {
                                try {
                                    const employeeIdGenerated = randomIdMaker();
                                    employee.createdAt = serverTimestamp();
                                    const employeeRef = doc(db, "employees", employeeIdGenerated);
                                    await setDoc(employeeRef, employee)
                                    alert('Add successful. Please refresh');
                                } catch (err) {
                                    console.log("err: ", err)
                                }
                            }

                        })

                        await Promise.all(updatePromises)
                        let errMsg = convert2string(consoleError);
                        setMessage(errMsg);
                    } else {
                        let errorMsg = convert2string(errorList);
                        setMessage(errorMsg);
                    }
                },
            });
        }
        // handleClose();
    };

    const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
        noClick: true,
        noKeyboard: true,
        accept: "csv",
    });

    const files = acceptedFiles.length > 0 ? acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    )) : null;

    const exporter = () => {
        const data = [
            {
              id: '',
              fullName: '',
              cardNumber: '',
              employeeNumber: '',
              credit: '',
              recurringCredit: '',
              'type.id': '',
              'type.name': '',
              email: '',
              mobile: '',
              isActive: '',
              createdAt: '',
              updatedAt: '',
              recurringCreditType: '',
              costCenter: ''
            },
        ];
        const headers = Object.keys(data[0]);
        const csvRows = data.map(row => 
            headers.map(fieldName => JSON.stringify(row[fieldName], (_, value) => (value ?? ""))).join(",")
        );

        csvRows.unshift(headers.join(","));
        const csvContent = csvRows.join("\n");
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'example.csv';
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={modalOpen}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <Typography variant={"h6"}>
                        Please Upload Your Excel File Here. <br />
                    </Typography>
                    <Typography variant={"body1"}>
                        Rules: <br />
                        1. Follow the format to <b>add</b> new employees. &nbsp;
                        <u>
                        <a onClick={exporter} style={{ color: 'blue', background: 'none', border: 'none', padding: 0, textDecoration: 'underline', cursor: 'pointer' }}>
                            Click here to get the excel format
                        </a>
                        </u>
                        <br />
                        2. Press the <b>export</b> button to get latest employees information for <b>update</b>
                        <br />
                        3. <b>type.id, type.name</b> can refer to Type Lists <br />
                        4. Required Field: <b>cardNumber</b>, <b>fullName</b>, <b>credit</b>, <b>type.id</b>, and <b>type.name</b>
                        <br />
                        5. Auto Generated Field: <b>id</b>, <b>isActive</b>, <b>createdAt</b> and <b>updatedAt</b>
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Typography>
                        <div className={`p-6 my-2 mx-auto max-w-md border-2`}>
                            <div {...getRootProps({ className: "dropzone", style: styleDropzone })}>
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop a csv file here </p>
                                <br />
                                <br />
                                <button type="button" onClick={open}>
                                    Open File Dialog
                                </button>
                            </div>
                            <aside>
                                <h4>Files</h4>
                                <ul>{files}</ul>
                            </aside>
                        </div>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" autoFocus color="primary" onClick={handleUploadEvent}>
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>
            {message && <ErrorDialog value={message} />}
        </>
    );
};

export default EmployeeImport;